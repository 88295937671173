import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProductBuilderContext } from "../../context";
import Map from "../Map";
import { getArray, startSpinLoader } from "../../actions/customFn";
import { uplodProductNew } from "../../actions/productBuilderAction";
import { useTranslation } from "react-i18next";
import Geosuggest from "react-geosuggest";
import { useScreenshot } from "use-screenshot-hook";

const TicketRedemption = (props) => {
  const { t } = useTranslation();
  const { tour_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const languageReducer = useSelector((state) => state.languageReducer);
  const [isDragged, setIsDragged] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
 

  const mapContainerRef = useRef(null);
 
  const { image, takeScreenshot } = useScreenshot({
    ref: mapContainerRef,
  });

  const {
    setTourId,
    getData,
    productType,
    setSpinLoader,
    saveAndExit,
    productInfo,
    exitRoute,
    googleKey,
    languageIdSelected
  } = useContext(ProductBuilderContext);

  const [ticketRedemptionData, setData] = useState("");

  const [formData, setFormData] = useState({
    tourId: tour_id,
    step: "seventeen",
    product_type: productType,
    language: productInfo.productLanguageId,
    redemption_map_option:"",
    redemption_ticket_type: "DIRECT_ENTRY",
    instructions: "",
    redemption_data: [
      {
        redemption_location: "",
        redemption_location_id: "",
        redemption_latitude: "",
        redemption_longitude: "",
        redemption_location_obj: "",
      },
    ],
    isChange:0
  });

  useEffect(() => {
    if (languageReducer != "") {
      getData("seventeen", setData);
    }
  }, [languageReducer, languageIdSelected]);

  useEffect(() => {
    if (languageReducer && ticketRedemptionData) {
      setFormData(() => ({
        ...ticketRedemptionData.product_data,
        tourId: tour_id,
        step: "seventeen",
        product_type: ticketRedemptionData.ProductType,
        language: productInfo.productLanguageId,
        redemption_map_option:"",
      }));
    }
  }, [ticketRedemptionData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value,'isChange':1 }));
  };

   const [geoSuggestKey, setGeoSuggestKey] = useState(Date.now());
  const handleGoogleAddress = async (place, index) => {
    if(place){
      const { lat, lng } = place.location
      const address = place.label
  
      formData.redemption_data[index].redemption_location_id = "";
      formData.redemption_data[index].redemption_location = address;
      formData.redemption_data[index].redemption_latitude = lat;
      formData.redemption_data[index].redemption_longitude = lng;
  
      const newUpdateData = formData.redemption_data;
      setFormData((formData) => ({
        ...formData,
        redemption_data: newUpdateData,
        isChange:1
      }));

      setGeoSuggestKey(Date.now());
    }

    captureMap();
 
  };

  const addMore = () => {
    const duplicate_data = {
      redemption_location_id: "",
      redemption_location: "",
      redemption_latitude: "",
      redemption_longitude: "",
    };
    setFormData((formData) => ({
      ...formData,
      redemption_data: [...formData.redemption_data, duplicate_data],
    }));
  };

  const removeLocation = (key, index) => {
    let data = formData.redemption_data;
    data.splice(index, 1);
    setFormData((prev) => ({ ...prev, redemption_data: data,isChange:1 }));
  };

  const submit = () => {
    let nextRoute = "my-tours";
    if (languageReducer !== "") {
      startSpinLoader("cover-spin");
      let sendData = {
        ...formData,
        redemption_location_obj: getArray(
          formData.redemption_data,
          "redemption_location_obj"
        ),
        redemption_location: getArray(
          formData.redemption_data,
          "redemption_location"
        ),
        redemption_location_id: getArray(
          formData.redemption_data,
          "redemption_location_id"
        ),
        redemption_latitude: getArray(
          formData.redemption_data,
          "redemption_latitude"
        ),
        redemption_longitude: getArray(
          formData.redemption_data,
          "redemption_longitude"
        ),
      };

      dispatch(
        uplodProductNew(
          languageReducer,
          setTourId,
          navigate,
          sendData,
          nextRoute,
          "",
          "",
          "",
          "",
          "",
          "",
          setSpinLoader
        )
      );
    }
  };



  useEffect(() => {
      captureMap();
  }, [formData.redemption_location]);



  

  // const captureMap = async (event) => {
  //   // Delay to ensure the map is fully loaded before capture
  //   // setTimeout(() => {
  //   //   try {
  //   //     const mapContainer = mapContainerRef.current;

  //   //     if (mapContainer) {
  //   //       takeScreenshot({ useCORS: true, cacheBust: true })
  //   //         .then((dataUrl) => {
  //   //           // Convert the data URL to Blob
  //   //           const byteString = atob(dataUrl.split(',')[1]); // Decode base64 string
  //   //           const arrayBuffer = new ArrayBuffer(byteString.length);
  //   //           const uintArray = new Uint8Array(arrayBuffer);
  //   //           for (let i = 0; i < byteString.length; i++) {
  //   //             uintArray[i] = byteString.charCodeAt(i);
  //   //           }
  //   //           const blob = new Blob([uintArray], { type: "image/png" }); // Create Blob from the decoded string

  //   //           // Store Blob in formData
  //   //           setFormData((prev) => ({
  //   //             ...prev,
  //   //             redemption_map_option: blob,
  //   //           }));
  //   //         })
  //   //         .catch((error) => {
  //   //           console.error("Failed to capture map:", error);
  //   //         });
  //   //     }
  //   //   } catch (error) {
  //   //     console.error("Error during map capture:", error);
  //   //   }
  //   // }, 3000); 
  // };




  const captureMap = async (event) => {
 
      try {
        const mapContainer = mapContainerRef.current;

        if (mapContainer) {
         
          await new Promise((resolve) => setTimeout(resolve, 1000)); 
         
            takeScreenshot({ useCORS: true, cacheBust: true })
              .then((dataUrl) => {
                console.log("Captured Map Screenshot");
              
                setFormData((prev) => ({
                  ...prev,
                  redemption_map_option: dataUrl, 
                }));
              })
              .catch((error) => {
                console.error("Failed to capture map:", error);
              });
          
        }
      } catch (error) {
        console.error("Error during map capture and share:", error);
      }
   
  };



  useEffect(() => {
    if (isFirstRender == false) {
      submit();
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  // ==========Google map code=========
  const fitBounds = async (map) => {
    let bounds = new window.google.maps.LatLngBounds();
    if (map != null) {
      formData.redemption_data.forEach((marker) => {
        bounds.extend(
          new window.google.maps.LatLng(
            marker.redemption_latitude,
            marker.redemption_longitude
          )
        );
      });
      await map.fitBounds(bounds);
    }
  };

  const handleMapMounted = (map, key) => {
    if (map != null) {
      fitBounds(map, key);
    }
  };

  const handleBoundsChanged = (map, key) => {
    if (map != null) {
      fitBounds(map, key);
    }
  };
  // ==========Google map code=========

 

  return (
    <>
      <div className="meet-pickup-main">
        <div className="ticket_redemption activity_productdetail">
          <h1>{t("_Add_details_about_your_ticket_redemption")}</h1>
          <h6>
            {t("_Do_travelers_use_a")} <b>{t("_Separate_entry_ticket")}</b>?
          </h6>

          <div className="box-nt-ul-selcet">
            <select
              class="form-select"
              aria-label="Default select example"
              name="redemption_ticket_type"
              value={formData.redemption_ticket_type}
              onChange={(e) => handleChange(e)}
            >
              <option value="DIRECT_ENTRY">
                {t("_no,_this_is_a_direct_entry_ticket")}
              </option>
              <option value="EXCHANGE_FOR_DIRECT_ENTRY">
                {t(
                  "_yes,_they_will_have_to_exchange_this_ticket_for_a_direct_entry_ticket"
                )}
              </option>
              {/* <option value="DIRECT_ENTRY_TO_DELIVERED_TICKET">
                {t(
                  "_yes,_they_will_have_to_exchange_this_ticket_for_a_direct_entry_ticket"
                )}
              </option> */}
            </select>
          </div>

          {formData.redemption_ticket_type == "EXCHANGE_FOR_DIRECT_ENTRY" && (
            <>
              <h6>{t("_Where_do_travelers_exchange_their_tickets?")}</h6>
              <div className="mb-4">
                {formData.redemption_data.map((value, index) => {
                  return (
                    <div key={index}>
                      <div className="redemption-locations">
                        <div
                          key={index}
                          className="mb-2 location-search-div"
                          name={`redemption_location.${index}`}
                          id={`redemption_location.${index}`}
                        >
                          <div>
                            <Geosuggest
                               key={`${geoSuggestKey}`} 
                              placeholder="Search Place here"
                              onSuggestSelect={(place) => handleGoogleAddress(place, index)}
                              initialValue={value.redemption_location}
                            />
                          </div>
                        </div>
                        <div
                          className="cursor-pointer delete-icon"
                          onClick={(e) => {
                            removeLocation(index);
                          }}
                        >
                          {index !== 0 && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                                fill="#fc5301"
                              />
                              <path
                                d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                                fill="#fc5301"
                              />
                              <path
                                d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                                fill="#fc5301"
                              />
                              <path
                                d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                                fill="#fc5301"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <button className="trasnparent_buton" onClick={() => addMore()}>
                {t("_add_another_redemption_point")}
              </button>

              <div className="map-inner">
                <div className="google-map-code vishal"  ref={mapContainerRef}>
                  <Map
                    center={{ lat: 22.7527421, lng: 75.88371599999999 }}
                    zoom={12}
                    mapName="ticket_redemption"
                    places={{
                      multiple: formData.redemption_data,
                    }}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `300px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    onMapMounted={(map) =>
                      handleMapMounted(map, "ticket_redemption")
                    }
                    onBoundsChanged={(map) =>
                      handleBoundsChanged(map, "ticket_redemption")
                    }
                    handleGoogleAddress={handleGoogleAddress}
                    setIsDragged={setIsDragged}
                   
                  />
                </div>
              </div>
            </>
          )}

          <>
            <h6>
              {t("_Add_or_edit_ticket_redemption_instructions_(optional)")}
            </h6>
            <textarea
              name="instructions"
              id="instructions"
              rows="3"
              placeholder='Please only include information related to your tickets—for example, "look for the ticket booth with the red sign."'
              onChange={(e) => handleChange(e)}
              value={formData.instructions}
            ></textarea>
          </>

          <div className="btn-last-save">
            <button className="btn-all-save" onClick={() => submit()}>
              {t("_Save_&_Exit")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketRedemption;
