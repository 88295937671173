import { useContext, useState, useEffect, useRef } from "react";
import {
  PrincingScheduleContext,
  ProductBuilderContext,
} from "../../../context";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import { Select, MenuItem } from "@mui/material";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import customMarker from "../../../assets/images/google_marker.png"; // marker image
import { images, dataURItoBlob } from "../../../actions/customFn";
import NameOptionModal from "../ProductModal/NameOptionModal";
import Geosuggest from "react-geosuggest";
import { GoogleMap, Marker } from "@react-google-maps/api"; // marker image
import * as htmlToImage from "html-to-image";
import { useScreenshot } from "use-screenshot-hook";
const PricingScheduleStepOne = () => {
  const { t } = useTranslation();
  const [isDragged, setIsDragged] = useState(false);
  const {
    formData,
    setFormData,
    formErrors,
    slidepage,
    setSlidePages,
    optionImage,
    setOptionImage,
  } = useContext(PrincingScheduleContext);
  const { googleKey, productType } = useContext(ProductBuilderContext);
  const mapContainerRef = useRef(null);
  const cancelledTypeOption = {
    day: t("_Days"),
    hour: t("_Hours"),
    minute: t("_Minutes"),
  };

  const handleChange = (event) => {
    const { value, name, checked } = event.target;
    if (name == "likely_to_sell_out") {
      setFormData((prev) => ({ ...prev, [name]: checked ? "yes" : "no" }));
    } else if (
      name == "cancelled_time" ||
      name == "duration_time" ||
      name == "maximum_person_booking"
    ) {
      const onlyNumbers = /^[0-9]*$/;
      if (onlyNumbers.test(value) === true) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const [geoSuggestKey, setGeoSuggestKey] = useState(Date.now());

  const handleGoogleAddress = async (place, dragged = "false") => {
    if (place) {
      dragged == "true" ? setIsDragged(true) : setIsDragged(false);
      const { lat, lng } = place.location;
      const address = place.label;

      setFormData((prev) => ({
        ...prev,
        option_location_obj: place,
        option_address: address,
        option_latitude: lat,
        option_longitude: lng,
      }));

      setGeoSuggestKey(Date.now());
    }
  };

  const [eyemodal, setEyemodal] = useState(false);

  const handleOpenEyemodal = () => {
    setEyemodal(true);
  };
  const handleCloseEyemodal = () => {
    setEyemodal(false);
  };

  // Set the option_location_obj for edit
  useEffect(() => {
    if (formData?.option_location_obj?.location) {
      const { lat, lng } = formData?.option_location_obj?.location;
      const address = formData?.option_location_obj?.label;

      setFormData((prev) => ({
        ...prev,
        option_latitude: parseFloat(lat),
        option_longitude: parseFloat(lng),
        option_address: address,
      }));
    }
  }, [formData?.option_location_obj]);

  // Function to capture the map as an image
  useEffect(() => {
    if (slidepage == 1) {
      captureMap();
    }
  }, [formData.option_address]);


  const { image, takeScreenshot } = useScreenshot({
    ref: mapContainerRef,
  });

  const captureMap = async (event) => {
    setTimeout(() => {
      try {
        const mapContainer = mapContainerRef.current;

        if (mapContainer) {
          if (mapContainerRef.current) {
            takeScreenshot({ useCORS: true, cacheBust: true })
              .then((dataUrl) => {
                setFormData((prev) => ({
                  ...prev,
                  optionImage: dataUrl, // Store Blob in formData
                }));
              })
              .catch((error) => {
                console.error("Failed to capture map:", error);
              });
          }
        }
      } catch (error) {
        console.error("Error during map capture and share:", error);
      }
    }, 3000);
  };
  const dataUrlToFile = (dataUrl, fileName) => {
    const [header, data] = dataUrl.split(",");
    const mime = header.split(":")[1].split(";")[0];
    const binary = atob(data);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new File([new Uint8Array(array)], fileName, { type: mime });
  };

  return (
    <>
      <div className="inclusion_models pricing_schedule_modal">
        <h2>{t("_Step")} 1 </h2>
        <h1>{t("_Add_attributes_that_apply_to_this_option")}</h1>
        <p>
          {t("_A_detailed_itinerary_helps_travelers_know_what_to_expect.")}.
          <Link>{t("_Learn_more")}</Link>
        </p>

        <h6>
          {t("_Name_this_option")}{" "}
          <button
            className="eyeopen_button"
            onClick={() => handleOpenEyemodal()}
          >
            <img src={images["eye_view.png"]} alt="" />
          </button>{" "}
        </h6>
        <div>
          <input
            type="text"
            name="option_title"
            value={formData.option_title}
            onChange={handleChange}
          />
        </div>

        <h6>
          {t("_Option_Location")}{" "}
          <button
            className="eyeopen_button"
            onClick={() => handleOpenEyemodal()}
          >
            <img src={images["eye_view.png"]} alt="" />
          </button>{" "}
        </h6>
        <div>
          <div name={`option_address`} id={`option_address`}>
            <div>
              <Geosuggest
               key={`${geoSuggestKey}`} 
                placeholder="Search Place here"
                onSuggestSelect={(place) => handleGoogleAddress(place, "false")}
                initialValue={formData.option_address}
              />
            </div>
            {formData?.option_latitude && formData?.option_longitude && (
              <div
                style={{
                  height: "300px",
                  width: "100%",
                  marginTop: "20px",
                  // opacity: "0",
                  // position: "absolute",
                }}
                id="map-option"
                ref={mapContainerRef}
              >
                <GoogleMap
                  mapContainerStyle={{ height: "100%", width: "100%" }}
                  center={{
                    lat: formData?.option_latitude,
                    lng: formData?.option_longitude,
                  }}
                  zoom={15}
                >
                  <Marker
                    position={{
                      lat: formData?.option_latitude,
                      lng: formData?.option_longitude,
                    }}
                    icon={{
                      url: customMarker,
                      // scaledSize: new window.google.maps.Size(40, 45),
                    }}
                  />
                </GoogleMap>
              </div>
            )}
          </div>
        </div>
        <h6>
          {t("_option_reference_code")}{" "}
          <button
            className="eyeopen_button"
            onClick={() => handleOpenEyemodal()}
          >
            <img src={images["eye_view.png"]} alt="" />
          </button>{" "}
        </h6>
        <div className="tourfile_input">
          <input
            type="text"
            name="reference_code"
            value={formData.reference_code}
            onChange={handleChange}
          />
          {/* {formErrors.reference_code && (
        <div className="invalid-feedback">
          {formErrors.reference_code}
        </div>
      )} */}
        </div>

        <h6>{t("_option_description")}</h6>
        <div className="option_input">
          <textarea
            // className={
            //   `form-control  ` +
            //   (formErrors.option_description ? " is-invalid" : "")
            // }
            id="option_description"
            name="option_description"
            value={formData.option_description}
            rows="3"
            cols="80"
            onChange={handleChange}
          />
          {/* {formErrors.option_description && (
        <div className="invalid-feedback">
          {formErrors.option_description}
        </div>
      )} */}
        </div>

        {/* <div className="checkbox_product_title">
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="likely_to_sell_out"
                    checked={formData.likely_to_sell_out === "yes" ? true : false}
                    onChange={handleChange}
                />
                <span>{t("_likely_to_sell_out")}</span>
            </div> */}

        {(productType == "tour" || productType == "ticket_and_pass") && (
          <div className="activity_setup">
            <h6>{t("_activity_setup")}</h6>
            <h6>{t("_is_this_a_private_activity")}?</h6>

            <div className="radio_btn_transpoetantion">
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  <p>
                    {t(
                      "This_means_that_only_one_group_or_person_can_participate_in_this_activity._There_won’t_be_other_customers_in_the_same_activity."
                    )}
                  </p>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="is_private"
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Non-private"
                    control={<Radio />}
                    label={t("_No")}
                    checked={
                      formData.is_private === "Non-private" ? true : false
                    }
                    // onChange={(e) => handleChange(e, "is_private")}
                  />
                  <FormControlLabel
                    value="Private"
                    control={<Radio />}
                    label={t("_Yes")}
                    checked={formData.is_private === "Private" ? true : false}
                    // onChange={(e) => handleChange(e, "private_shared")}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        )}

        {productType == "ticket" && (
          <div className="radio_btn_transpoetantion">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                <p>
                  {t(
                    "_Will_the_customer_skip_the_existing_line_to_get_in_If_so_which_line_"
                  )}
                </p>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="existing_line"
                onChange={handleChange}
              >
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label={t("_No")}
                  checked={formData.existing_line === "no" ? true : false}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={t("_Yes")}
                  checked={formData.existing_line === "yes" ? true : false}
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}

        {/* 
        <div className="confirm_booking_option">
          <div className="advance_option_text">
            <h4>
              How far in advance do you stop accepting new bookings before the
              activity starts?
            </h4>
            <div className="search_minutes_option">
              <div className="search_minutes_option_setup">
                <Select
                  sx={{
                    width: "100%",
                  }}
                  className={
                    `form-control input_username new-edit-main-all` +
                    (formErrors.maximum_person_booking ? " is-invalid" : "")
                  }
                  id="cut_off_time"
                  name="cut_off_time"
                  displayEmpty
                  value={formData.cut_off_time}
                  onChange={handleChange}
                >
                  {pricingScheduleData.cut_off_time &&
                    Object.entries(pricingScheduleData.cut_off_time).map(
                      (item, index) => {
                        return (
                          <MenuItem key={index} value={item[0]}>
                            {item[1]}
                          </MenuItem>
                        );
                      }
                    )}
                </Select>
                {formErrors.cut_off_time && (
                  <div className="invalid-feedback">
                    {formErrors.cut_off_time}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div> */}

        <div className="option_reference">
          <h6>{t("_Maximum_person_booking")}</h6>
          <p>{t("_This_means_how_many_persons_can_be_booked_at_that_time")}</p>
          <div className="tourfile_input">
            <input
              className={
                `form-control  ` +
                (formErrors.maximum_person_booking ? " is-invalid" : "")
              }
              type="text"
              name="maximum_person_booking"
              value={formData.maximum_person_booking}
              onChange={handleChange}
            />
            {formErrors.maximum_person_booking && (
              <div className="invalid-feedback">
                {formErrors.maximum_person_booking}
              </div>
            )}
          </div>
          <h6 className="tax-msg ">{t("_note")}:</h6>
          <span> 0 {t("_for_unlimited_booking")}</span>
        </div>

        {/* <div className="option_setup mt-3 advance_option_text">
                <h4>
                    {t(
                        "_How_long_before_the_activity_can_the_customer_cancel_or_change_a_reservation_without_losing_money"
                    )}
                </h4>
                <Switch
                    checked={
                        formData.is_cancelled == true || formData.is_cancelled == "true"
                            ? true
                            : false
                    }
                    onChange={(checked) =>
                        setFormData((prev) => ({ ...prev, is_cancelled: checked }))
                    }
                />

                <div className="checkbox_product_title"></div>
            </div>

            <div className="time_data_main">
                <div className="radio_btn_transpoetantion">
                    {(formData.is_cancelled == true ||
                        formData.is_cancelled == "true") && (
                            <div className="icon_search_button pricing-schedule-cancel-time">
                                <input
                                    type="text"
                                    name="cancelled_time"
                                    value={formData.cancelled_time}
                                    onChange={handleChange}
                                />
                                <Select
                                    sx={{
                                        width: "100%",
                                    }}
                                    className="input_username new-edit-main-all select-field"
                                    id="cancelled_type"
                                    name="cancelled_type"
                                    displayEmpty
                                    value={formData.cancelled_type}
                                    onChange={handleChange}
                                >
                                    {Object.entries(cancelledTypeOption).map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item[0]}>
                                                {item[1]}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                        )}
                </div>
            </div> */}

        {/* <div className='pricebelow_allbutnsection'>
                <button
                    className="remove-allcancel mr-4"
                    onClick={CloseAddOPtionModel}
                >
                    Cancel
                </button>

                <div className='slide_circles'>
                    <div className='circle_one darkcircle'></div>
                    <div className='circle_one'></div>
                    <div className='circle_one'></div>
                </div>

                <button className="btnall-savenext " onClick={() => {
                    setSlidePages(2);
                    scrollToDiv();
                }
                } >Next</button>
            </div> */}
      </div>

      <NameOptionModal
        eyemodal={eyemodal}
        handleCloseEyemodal={handleCloseEyemodal}
      />
    </>
  );
};
export default PricingScheduleStepOne;
