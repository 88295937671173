import React, { useContext, useEffect } from "react";
import "flatpickr/dist/flatpickr.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextField, Autocomplete, Select, MenuItem, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProductBuilderContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import {
  getTopAttractionList,
  uplodProductNew,
} from "../../actions/productBuilderAction";
import { getStateList } from "../../actions/stateAction";
import { getCityList } from "../../actions/cityAction";
import { startSpinLoader, validateField } from "../../actions/customFn";
import AddNewCityModal from "./AddNewCityModal";
import Swal from "sweetalert2";

const ExtraInfromation = (props) => {
  const { tour_id } = useParams();
  const { t } = useTranslation();
  const phoneCodeReducer = useSelector((state) => state.phoneCodeReducer);
  const {
    getData,
    setDataLoad,
    dataLoad,
    setSpinLoader,
    saveAndExit,
    exitRoute,
    productInfo,
    languageIdSelected,
    isConfirmationModal,
    setIsConfirmationModal,
  } = useContext(ProductBuilderContext);
  const languageReducer = useSelector((state) => state.languageReducer);
  const [moreContentData, setData] = useState("");

  const [formData, setFormData] = useState({
    tourId: tour_id,
    step: "eleven",
    language: productInfo.productLanguageId,
    mandatory_item: [],
    not_allowed_items: [],
    pre_booking_info: "",
    emergency_contact_info: "",
    emergency_contact_phone_code: "",
    emergency_contact_number: "",
    more_information:"",
  });
  const [formErrors, setFormError] = useState({});
  const errors = {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const homeData = useSelector((state) => state.home);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [addCityModalShow, setAddCityModalShow] = useState(false);
  const [phoneCodeList, setPhoneCodeList] = useState("");
  const [countryFlag, setCountryFlag] = useState("");
  const [phone_code, setPhoneCode] = useState("");

  useEffect(() => {
    if (moreContentData) {
      setFormData((prev) => ({
        ...prev,
        mandatory_item: moreContentData.product_data.mandatory_items
          ? moreContentData.product_data.mandatory_items
          : [],
          not_allowed_items: moreContentData.product_data.not_allowed_items
          ? moreContentData.product_data.not_allowed_items
          : [],
          pre_booking_info: moreContentData.extra_info_details?.pre_booking_info
          ? moreContentData.extra_info_details.pre_booking_info
          : "",
        
          emergency_contact_phone_code: moreContentData.product_data.emergency_contact_phone_code
          ? moreContentData.product_data.emergency_contact_phone_code
          : "",
          emergency_contact_number: moreContentData.product_data.emergency_contact_number
          ? moreContentData.product_data.emergency_contact_number
          : "",

          more_information: moreContentData.extra_info_details.more_information
          ? moreContentData.extra_info_details.more_information
          : "",
          
      }));
      setDataLoad(false);
      setIsConfirmationModal(false);
    }
  }, [moreContentData]);

  useEffect(() => {
    getData("eleven", setData);
  }, [languageIdSelected, languageReducer]);

  useEffect(() => {
    if (formData?.emergency_contact_phone_code) {
      setPhoneCode(parseInt(formData.emergency_contact_phone_code));
    }
  }, [formData?.emergency_contact_phone_code]);
  

  const handleChange = (e, data, type) => {
    const onlyNumbers = /^[0-9]*$/;
    if (type === "emergency_contact_number" || type === "pre_booking_info" || type === "more_information") {
      if(type === "emergency_contact_number"){
        if (onlyNumbers.test(data) === true) {
          setFormData((prev) => ({ ...prev, [type]: data }));
        }
      }else{
        setFormData((prev) => ({ ...prev, [type]: data }));
      }
    } else {

      let IdArray = [];
      data.forEach((item) => {
        IdArray.push(item.id);
      });
      setFormData((prev) => ({ ...prev, [type]: IdArray }));
 
    }
  };

  useEffect(() => {
    if (phoneCodeReducer) {
      setPhoneCodeList(phoneCodeReducer);
    }
  }, [phoneCodeReducer ]);

  useEffect(() => {
    if (phoneCodeList && phoneCodeList.length > 0) {
      setFlag();
    }
  }, [phoneCodeList , formData?.emergency_contact_phone_code ]);

  const setFlag = () => {
    if (phoneCodeList) {
      let object = phoneCodeList.find(
        (option) => option.code == Number(formData?.emergency_contact_phone_code)
      );
      setCountryFlag(object?.flag);
    }
  };

  // function containsAny(source, target) {
  //   var result = source.filter(function (item) {
  //     return target.indexOf(item.id) > -1;
  //   });
  //   return result;
  // }


  function containsAny(source, target) {

   
    // Ensure target is an array
    if (!Array.isArray(target)) {
        console.error("Target must be an array");
        return [];
    }

    // Filter source based on whether target contains the item's id
    return source.filter(function (item) {
        return target.includes(item.id); // Use includes for array check
    });
}


  // ==============country/state/city starts=============
  useEffect(() => {
    setCountryList(homeData.countries);
    if (formData.country) {
      dispatch(getStateList(setStateList, formData.country));
    }
  }, [homeData, formData.country]);

  useEffect(() => {
    if (formData.state) {
      dispatch(getCityList(setCityList, formData.state));
    }
  }, [formData.state]);




  // ==============country/state/city ends=============

  const [topAttractionList, setTopAttractionList] = useState([]);

  useEffect(() => {
    if (languageReducer !== "" && formData.state) {
      let queryData = {
        state: formData.state,
      };
      dispatch(
        getTopAttractionList(
          languageReducer,
          navigate,
          queryData,
          setTopAttractionList
        )
      );
    }
  }, [languageReducer, languageIdSelected, formData.state]);

  const SubmitDataFn = (exit) => {
    startSpinLoader("cover-spin");
    let nextRoute = exit || "price-schedule";
    dispatch(
      uplodProductNew(
        languageReducer,
        "",
        navigate,
        formData,
        nextRoute,
        "",
        "",
        "",
        "",
        "",
        "",
        setSpinLoader
      )
    );
  };



  const handlePhoneCode = (event, data) => {
    if (data) {
      const { code } = data;
      setFormData((prev) => ({ ...prev, emergency_contact_phone_code: code }));
      setCountryFlag(data?.flag);
    } else {
      setCountryFlag("");
      setFormData((prev) => ({ ...prev, emergency_contact_phone_code: "" }));
    }
  };

  const submitData = (exit) => {



    let validate = {
      
    };
    validateField(validate, { ...formData }).then((res) => {
      if (res == true) {
        if (isConfirmationModal) {
          Swal.fire({
            title: t("_are_you_sure"),
            text: t(
              "_your_product_modification_require_approval_can_take_up_to_24_hours_for_revision_your_product"
            ),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "rgb(155 176 199)",
            cancelButtonColor: "rgb(227 46 46)",
            confirmButtonText: "Yes",
          }).then((res) => {
            if (res.isConfirmed === true) {
              if (languageReducer !== "") {
                SubmitDataFn(exit);
              }
            }
          });
        } else {
          SubmitDataFn(exit);
        }
      } else {
        setDataLoad(true);
        setSpinLoader((status) => !status);
      }
    });
  };

  useEffect(() => {
    if (isFirstRender == false) {
      submitData(exitRoute);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  if(phoneCodeList && phoneCodeList.length > 0){

    console.log("dsgshshsh",phoneCodeList.find(
      (obj) => obj.code == parseInt(formData?.emergency_contact_phone_code)
    ))
  }


  return (
    <>
      {formData !== "" && moreContentData && (
        <div className="meet-pickup-main">
          <div className="product_morecontentpage">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="icon_search_peoduct_title">
                  <h6>
                    {" "}
                    What mandatory items must the customer bring with them?
                  </h6>

                  <Autocomplete
                    multiple
                    options={moreContentData.mendatory_items}
                    onChange={(e, data) => handleChange(e, data, "mandatory_item")}
                    // disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} />}
                    value={containsAny(
                      moreContentData.mendatory_items,
                      formData.mandatory_item
                    )}
                    className="mandatory_item"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="search_product_title">
                  <h6>
                    What’s not allowed?
                    <span className="required-field">*</span>
                  </h6>
                  {/* <span className="required">*</span> */}
                  <Autocomplete
                    multiple
                    options={moreContentData?.not_allowed_item}
                    getOptionLabel={(option) => option.title}
                    onChange={(e, data) =>
                      handleChange(e, data, "not_allowed_items")
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    value={containsAny(
                      moreContentData?.not_allowed_item,
                      formData?.not_allowed_items
                    )}
                    name="not_allowed_items"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="search_product_title">
                  <h6>
                    {" "}
                    Add custom information before booking{" "}
                    <span className="required-field">*</span>
                  </h6>
                  <div>
                    <div className="position-relative">
                      <textarea
                        name="pre_booking_info"
                        id="description"
                        onChange={(e) => {
                          handleChange(e, e.target.value, "pre_booking_info");
                        }}
                        value={formData.pre_booking_info}
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            

            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <label className="label_all mb-2">{t("_country_code")}</label>

                {(phoneCodeList) && (
                  <Autocomplete
                    id="emergency_contact_phone_code"
                    name="emergency_contact_phone_code"
                    className="new_filter_select"
                    sx={{ width: 200 }}
                    options={phoneCodeList}
                    onChange={(event, data) => handlePhoneCode(event, data)}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option.code + " " + option.label
                    }
                    value={
                      phoneCodeList.find(
                        (obj) => Number(obj.code) === Number(formData?.emergency_contact_phone_code || phone_code)
                      ) || null
                    }
                    
                   
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img src={option.flag} style={{ width: 25 }} alt="" />
                        {option.label} +({option.code})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t("_select_country_code")}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              {countryFlag && (
                                <img
                                  src={countryFlag}
                                  alt=""
                                  style={{ width: 25 }}
                                />
                              )}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
                
                {formErrors.emergency_contact_phone_code && (
                  <div className="invalid-feedback">
                    {formErrors.emergency_contact_phone_code}
                  </div>
                )}
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                <label className="label_all mb-2">{"Emergency contact number"}</label>
                <input
                  type="text"
                  id="emergency_contact_number"
                  name="emergency_contact_number"
                  value={formData?.emergency_contact_number}
                  placeholder={t("_phone_number")}
                  // className="form-control"
                  className={
                    `form-control ` +
                    (formErrors.emergency_contact_number ? " is-invalid" : "")
                  }

                  onChange={(e) => {
                    handleChange(e, e.target.value, "emergency_contact_number");
                  }}
              
                />
                {formErrors.emergency_contact_number && (
                  <div className="invalid-feedback">
                    {formErrors.emergency_contact_number}
                  </div>
                )}
              </div>
            </div>


            <div className="row">
              <div className="col-xl-12">
                <div className="search_product_title">
                  <h6>
                    What else do customers need to know before your activity?
                    <span className="required-field">*</span>
                  </h6>
                  <div>
                    <div className="position-relative">
                      <textarea
                        name="more_information"
                        id="more_information"
                        onChange={(e) => {
                          handleChange(e, e.target.value, "more_information");
                        }}
                        value={formData?.more_information}
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="btn-last-save">
              <button onClick={() => submitData()} className="btn-all-save">
                {t("_Save_&_continue")}
              </button>
            </div>
          </div>
          <AddNewCityModal
            AddCityModalShow={addCityModalShow}
            setCityModalShow={setAddCityModalShow}
            countryList={countryList}
            stateList={stateList}
            selectedCountryId={formData?.country}
            selectedStateId={formData?.state}
          />
        </div>
      )}
    </>
  );
};

export default ExtraInfromation;
