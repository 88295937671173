import React, { useContext, useEffect } from "react";
import "flatpickr/dist/flatpickr.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextField, Autocomplete, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProductBuilderContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import {
  getTopAttractionList,
  uplodProductNew,
} from "../../actions/productBuilderAction";
import { getStateList } from "../../actions/stateAction";
import { getCityList } from "../../actions/cityAction";
import { startSpinLoader, validateField } from "../../actions/customFn";
import AddNewCityModal from "./AddNewCityModal";
import Swal from "sweetalert2";

const ProductMoreContent = (props) => {
  const { tour_id } = useParams();
  const { t } = useTranslation();
  const {
    getData,
    setDataLoad,
    dataLoad,
    setSpinLoader,
    saveAndExit,
    exitRoute,
    productInfo,
    languageIdSelected,
    isConfirmationModal,
    setIsConfirmationModal
  } = useContext(ProductBuilderContext);
  const languageReducer = useSelector((state) => state.languageReducer);
  const [moreContentData, setData] = useState("");
  const [formData, setFormData] = useState({
    tourId: tour_id,
    step: "four",
    language: productInfo.productLanguageId,
    category: "",
    interest: [],
    product_title_type: [],
    top_attraction: [],
    country: "",
    state: "",
    city: "",
    isChange: 0,
    is_product_change:0
  });
  const [formErrors, setFormError] = useState({});
  const errors = {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const homeData = useSelector((state) => state.home);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [addCityModalShow, setAddCityModalShow] = useState(false);

  useEffect(() => {
    if (moreContentData) {
      setFormData((prev) => ({
        ...prev,
        category: moreContentData.product_data.category
          ? moreContentData.product_data.category
          : "",
        interest: moreContentData.product_data.interest
          ? moreContentData.product_data.interest
          : [],
        product_title_type: moreContentData.product_data?.product_type
          ? moreContentData.product_data.product_type
          : [],
        top_attraction: moreContentData.product_data?.top_attraction
          ? moreContentData.product_data.top_attraction
          : [],
        country: moreContentData.product_data.country
          ? moreContentData.product_data.country
          : "",
        state: moreContentData.product_data.state
          ? moreContentData.product_data.state
          : "",
        city: moreContentData.product_data.city
          ? moreContentData.product_data.city
          : "",
        isChange: moreContentData?.product_data?.isChange,
        is_product_change:moreContentData?.product_data?.is_product_change,
      }));
      setDataLoad(false);
      setIsConfirmationModal(false);
    }
  }, [moreContentData]);

  useEffect(() => {
    getData("four", setData);
  }, [languageIdSelected, languageReducer]);




  const handleOtherChanges = (e) => {
    const { name, value, checked } = e.target;
    if (name === "product_status" || name === "category") {


      console.log("product_statusproduct_statusproduct_statusproduct_status",formData?.is_product_change)
      setFormData((prev) => ({ ...prev, [name]: value }));

      if(formData?.is_product_change){
        setIsConfirmationModal(true);
        setFormData((prev) => ({ ...prev, isChange: 1 }));
      }
    

    } else if (
      name === "duration_text" ||
      name === "activity_text" ||
      name === "activity_text" ||
      name === "title" ||
      name === "description" ||
      name === "refrence_code"
    ) {
      setFormData((prev) => ({ ...prev, [name]: value }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: checked }));
    }
  };

  const handleChange = (e, data, type) => {
    if (type == "product_status") {
      setFormData((prev) => ({ ...prev, [type]: data }));
    } else {
      let IdArray = [];
      data.forEach((item) => {
        IdArray.push(item.id);
      });
      setFormData((prev) => ({ ...prev, [type]: IdArray }));
      if(formData?.is_product_change){
        setIsConfirmationModal(true);
        setFormData((prev) => ({ ...prev, isChange: 1 }));
      }
    }
  };

  function containsAny(source, target) {
    var result = source.filter(function (item) {
      return target.indexOf(item.id) > -1;
    });
    return result;
  }

  // const handleDate = (value, name) => {
  //   setFormData((prev) => ({ ...prev, [name]: value }))
  // };

  // ==============country/state/city starts=============
  useEffect(() => {
    setCountryList(homeData.countries);
    if (formData.country) {
      dispatch(getStateList(setStateList, formData.country));
    }
  }, [homeData, formData.country]);

  useEffect(() => {
    if (formData.state) {
      dispatch(getCityList(setCityList, formData.state));
    }
  }, [formData.state]);

  const handleCountry = (event, data) => {
    if (data) {
      const { id } = data;
      setFormData((prev) => ({
        ...prev,
        country: id,
        state: "",
        city: "",
        isChange: 1,
      }));
      setCityList([]);
      dispatch(getStateList(setStateList, id));
    }
  };

  const handleState = (event, data) => {
    if (data) {
      const { id } = data;
      setFormData((prev) => ({ ...prev, state: id, city: "", isChange: 1 }));
      dispatch(getCityList(setCityList, id));
    }
  };

  const handleCity = (event, data) => {
    if (data) {
      const { id } = data;
      setFormData((prev) => ({ ...prev, city: id, isChange: 1 }));
    }
  };
  // ==============country/state/city ends=============

  const [topAttractionList, setTopAttractionList] = useState([]);

  useEffect(() => {
    if (languageReducer !== "" && formData.state) {
      let queryData = {
        state: formData.state,
      };
      dispatch(
        getTopAttractionList(
          languageReducer,
          navigate,
          queryData,
          setTopAttractionList
        )
      );
    }
  }, [languageReducer, languageIdSelected, formData.state]);



  const SubmitDataFn = (exit)=>{
    startSpinLoader("cover-spin");
    let nextRoute = exit || "meeting-pickup";
    dispatch(
      uplodProductNew(
        languageReducer,
        "",
        navigate,
        formData,
        nextRoute,
        "",
        "",
        "",
        "",
        "",
        "",
        setSpinLoader
      )
    );
  }

  const submitData = (exit) => {

    console.log("exit" , exit)
    let validate = {
      category: "Required|",
      // product_type: "Required|",
      country: "Required|",
      state: "Required|",
      city: "Required|",
    };
    validateField(validate, { ...formData }).then((res) => {
    

      if (res == true) {
        if (isConfirmationModal) { 
          Swal.fire({
            title: t("_are_you_sure"),
            text: t(
              "_your_product_modification_require_approval_can_take_up_to_24_hours_for_revision_your_product"
            ),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "rgb(155 176 199)",
            cancelButtonColor: "rgb(227 46 46)",
            confirmButtonText: "Yes",
          }).then((res) => {
            if (res.isConfirmed === true) {
              if (languageReducer !== "") {
                SubmitDataFn(exit);
              }
            }
          });
        }else{
          SubmitDataFn(exit);
        }
      } else {
        setDataLoad(true);
        setSpinLoader((status) => !status);
      }
    });
  };

  useEffect(() => {
    if (isFirstRender == false) {
      submitData(exitRoute);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  return (
    <>
      {formData !== "" && moreContentData && (
        <div className="meet-pickup-main">
          <div className="product_morecontentpage">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="search_txteditor">
                  <h6>
                    {t("_category")}
                    <span className="required-field">*</span>
                  </h6>
                  <Select
                    sx={{
                      width: "100%",
                    }}
                    className="input_username new-edit-main-all"
                    id="category"
                    name="category"
                    displayEmpty
                    value={formData.category}
                    onChange={handleOtherChanges}
                  >
                    {moreContentData.categories.length > 0 &&
                      moreContentData.categories.map((value, index) => {
                        return (
                          <MenuItem key={index} value={value.id}>
                            {value.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="icon_search_peoduct_title">
                  <h6>{t("_interests")}</h6>

                  <Autocomplete
                    multiple
                    options={moreContentData.interests}
                    onChange={(e, data) => handleChange(e, data, "interest")}
                    // disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} />}
                    value={containsAny(
                      moreContentData.interests,
                      formData.interest
                    )}
                    className="input_username"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="search_product_title">
                  <h6>
                    {t("_product_type")}
                    <span className="required-field">*</span>
                  </h6>
                  {/* <span className="required">*</span> */}
                  <Autocomplete
                    multiple
                    options={moreContentData.product_type}
                    getOptionLabel={(option) => option.title}
                    onChange={(e, data) =>
                      handleChange(e, data, "product_title_type")
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    value={containsAny(
                      moreContentData.product_type,
                      formData.product_title_type
                    )}
                    name="product_title_type"
                  />
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="search_product_title">
                  <h6>
                    {t("_country")}
                    <span className="required-field">*</span>
                  </h6>
                  {countryList !== undefined && countryList.length > 0 && (
                    <Autocomplete
                      disablePortal
                      id="country"
                      name="country"
                      options={countryList}
                      value={
                        formData.country != ""
                          ? countryList.find(
                              (obj) => obj.id == formData.country
                            )
                          : ""
                      }
                      // value={countryList.find((obj) => obj.id == formData.country)}
                      onChange={(event, data) => handleCountry(event, data)}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="product_title_left">
                  {/* <div className="tourfile_input_product">
                    <div className="search_product_title">
                      <h6>{t("_country")}<span className="required-field">*</span></h6>
                      {countryList !== undefined && countryList.length > 0 && (
                        <Autocomplete
                          disablePortal
                          id="country"
                          name="country"
                          options={countryList}
                          value={countryList.find(
                            (obj) => obj.id === formData.country
                          )}
                          onChange={(event, data) => handleCountry(event, data)}
                          renderInput={(params) => (
                            <TextField {...params} label="" />
                          )}
                        />
                      )}
                    </div>
                  </div> */}

                  <div className="search_product_title">
                    <h6>
                      {t("_state")}
                      <span className="required-field">*</span>
                    </h6>
                    <Autocomplete
                      disablePortal
                      id="state"
                      name="state"
                      options={stateList}
                      value={
                        stateList.length > 0 && formData.state != ""
                          ? stateList.find((obj) => obj.id == formData.state)
                          : ""
                      }
                      onChange={(event, data) => handleState(event, data)}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  </div>

                  {formData.state && topAttractionList.length > 0 && (
                    <div className="search_product_title stickysection_culturedetail animate__animated animate__fadeInDown">
                      <h6>{t("_top_attraction")}</h6>
                      <Autocomplete
                        multiple
                        // options={moreContentData.top_attraction}
                        options={topAttractionList}
                        getOptionLabel={(option) => option.title}
                        onChange={(e, data) =>
                          handleChange(e, data, "top_attraction")
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                        // value={containsAny(
                        //   moreContentData.top_attraction,
                        //   formData.top_attraction
                        // )}
                        value={containsAny(
                          topAttractionList,
                          formData.top_attraction
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="product_title_right">
                  {/* <div className="checkbox_product_title">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="cultural_experiences"
                      name="cultural_experiences"
                      defaultChecked={formData.cultural_experiences}
                      value={formData.cultural_experiences}
                      onChange={handleOtherChanges}
                    />
                    <span>{t("_unforgettable_cultural_experiences")}</span>
                  </div>
                  <div className="checkbox_product_title">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="cultural_attractions"
                      name="cultural_attractions"
                      defaultChecked={formData.cultural_attractions}
                      value={formData.cultural_attractions}
                      onChange={handleOtherChanges}
                    />
                    <span>{t("_must_see_cultural_attractions")}</span>
                  </div> */}

                  <div className="search_product_title">
                    <h6>
                      {t("_city")}
                      <span className="required-field">*</span>
                    </h6>
                    <Autocomplete
                      disablePortal
                      id="city"
                      name="city"
                      options={formData.state === "" ? [] : cityList}
                      value={
                        cityList.length > 0 && formData.city != ""
                          ? cityList.find((obj) => obj.id == formData.city)
                          : ""
                      }
                      onChange={(event, data) => handleCity(event, data)}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  </div>
                  <button
                    type="button"
                    className="trasnparent_buton mt-2"
                    onClick={() => setAddCityModalShow(true)}
                  >
                    + {t("_Add_an_city")}
                  </button>
                </div>
              </div>
            </div>

            <div className="btn-last-save">
              <button onClick={() => submitData()} className="btn-all-save">
                {t("_Save_&_continue")}
              </button>
            </div>
          </div>
          <AddNewCityModal
            AddCityModalShow={addCityModalShow}
            setCityModalShow={setAddCityModalShow}
            countryList={countryList}
            stateList={stateList}
            selectedCountryId={formData?.country}
            selectedStateId={formData?.state}
          />
        </div>
      )}
    </>
  );
};

export default ProductMoreContent;
