import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { images, startSpinLoader, validateField } from "../../actions/customFn";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { uplodProductNew } from "../../actions/productBuilderAction";
import { ProductBuilderContext } from "../../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/fontawesome-free-regular";

const ProductUploadPhoto = (props) => {
  const { changeTab, setClickTabtopic } = props;
  const {
    getData,
    setDataLoad,
    dataLoad,
    setSpinLoader,
    saveAndExit,
    exitRoute,
    productInfo,
    languageIdSelected,
  } = useContext(ProductBuilderContext);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tour_id } = useParams();
  const [gallaryData, setData] = useState();
  const languageReducer = useSelector((state) => state.languageReducer);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [fileErrors, setFileErrors] = useState({});
  const ref = useRef();
  const handleClick = (e) => {
    ref.current.click();
  };
  const [formData, setFormData] = useState({
    files: [],
    image_id: [],
    cover_image_index: "0",
    cover_image_index: "0",
    language: productInfo.productLanguageId,
    cover_image_id: "",
    isChange: 0,
  });

  useEffect(() => {
    if (gallaryData) {
      let idArray = [];
      if (gallaryData?.product_data?.images?.length > 0) {
        idArray = gallaryData?.product_data.images?.map((item) => item.id);
      }
      setFormData({
        step: "three",
        tourId: tour_id,
        files: gallaryData?.product_data?.images?.map((image, index) => ({
          ...image,
          isCover:
            index.toString() ===
            gallaryData.product_data.cover_image.toString(),
        })),
        language: productInfo.productLanguageId,
        image_id: idArray,
        isChange: gallaryData.product_data?.isChange,
        cover_image_index: gallaryData.product_data.cover_image.toString(),
        copyright_check:
          gallaryData.product_data.images != undefined &&
          gallaryData.product_data.images.length > 0
            ? true
            : false,
      });
      // setDataLoad(false);
    }
  }, [gallaryData]);

  useEffect(() => {
    getData("three", setData);
  }, [languageReducer, languageIdSelected]);

  const handleChange = (e) => {
    const { name, value, files, checked } = e.target;
    if (name != "copyright_check") {
      setFormData((formData) => ({
        ...formData,
        "isChange": 1,
      }));
    }
    if (name == "cover_image_index") {
      setFormData((prevData) => {
        const updatedFiles = prevData.files.map((file, index) => ({
          ...file,
          isCover: index.toString() === value,
        }));

        return {
          ...prevData,
          cover_image_index: value.toString(),
          files: updatedFiles,
        };
      });
    } else if (name == "copyright_check") {
      setFormData((formData) => ({
        ...formData,
        [name]: checked,
      }));
    } else if (name === "files") {
      let validFiles = [];
      let errors = [];

      for (let i = 0; i < files.length; i++) {
        if (files[i]) {
          const fileSizeInMB = files[i].size / (1024 * 1024);
          if (fileSizeInMB > 20) {
            errors.push(`${files[i].name} is larger than 2 MB`);
          } else {
            validFiles.push({ id: "", image: files[i], isCover: false });
          }
        }
      }

      setFileErrors(errors);

      // Update formData only if there are valid files
      if (validFiles.length > 0) {
        setFormData((prevData) => {
          const updatedFiles = [...prevData.files, ...validFiles];

          const existingCoverIndex = prevData.files.findIndex(
            (file) => file.isCover
          );
          if (existingCoverIndex !== -1) {
            updatedFiles[existingCoverIndex].isCover = true;
          }

          return {
            ...prevData,
            files: updatedFiles,
          };
        });
      }
    }
  };

  const submitData = (exit) => {
    let validate = {
      files: "Required|minimum:3",
      // copyright_check: "RequiredNoBorder|",
    };

    if (formData.copyright_check == true) {
      let removefirstErrorElement =
        document.getElementsByName("copyright_check")[0];
      if (removefirstErrorElement) {
        removefirstErrorElement.classList.remove("form_error_field");
      }
    } else {
      validate = {
        ...validate,
        copyright_check: "RequiredNoBorder|",
      };
    }
    validateField(validate, { ...formData }).then((res) => {
      if (res == true) {
        if (languageReducer !== "") {
          startSpinLoader("cover-spin");
          const newForm = new FormData();
          let nextRoute = exit || "more-content";
          const coverImageIndex = formData.files.findIndex(
            (file) => file.isCover
          );
          // Get cover image ID
          const coverImageId = formData.image_id[coverImageIndex];
          newForm.append("tourId", tour_id);
          newForm.append("image_id", formData.image_id);
          newForm.append("step", "three");
          newForm.append("cover_image_index", coverImageIndex.toString());
          newForm.append("cover_image_id", coverImageId);
          newForm.append("language", formData.language);
          newForm.append("image_count", formData.files.length);

          // Append all files to the FormData object
          formData.files.forEach((file) => {
            newForm.append("files[]", file.image);
          });

          dispatch(
            uplodProductNew(
              languageReducer,
              "",
              navigate,
              newForm,
              nextRoute,
              "",
              "images_tab",
              "",
              "",
              "",
              "",
              setSpinLoader
            )
          );
        }
      } else {
        setSpinLoader((status) => !status);
      }
    });
  };

  useEffect(() => {
    if (isFirstRender == false) {
      submitData(exitRoute);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  const handleRemoveImage = (id, key) => {
    const updatedFiles = formData.files.filter((_, index) => index !== key);
    const updatedImageId = formData.image_id.filter(
      (_, index) => index !== key
    );
    setFormData((prevData) => ({
      ...prevData,
      files: updatedFiles,
      image_id: updatedImageId,
      isChange: 1,
    }));
  };

  return (
    <>
      <div className="meet-pickup-main">
        <div className="productbulid_uploadimage">
          <h1> {t("_Add_Photos")}</h1>
          <p>
            <b>
              {t(
                "_include_6+_high_quality,_candid_photos_that_showcase_your_experience"
              )}
            </b>
            .{" "}
            {t(
              "_This_will_help_you_attract_more_travelers,_and_meet_our_product"
            )}{" "}
            <b> {t("_Quality_Standards")}</b> .
          </p>

          <div className="checkbox_agreementimgupload">
            <input
              type="checkbox"
              name="copyright_check"
              onChange={(e) => handleChange(e)}
              checked={formData.copyright_check}
            />
            <label htmlFor="">{t("_own_the_copyright")}</label>
          </div>

          <div className="file_section_photo">
            <div className="file_selection_box">
              <img src={images["file_main.png"]} alt="" />
              <p>{t("_Upload_File")}</p>
              <button className="btn_select" onClick={handleClick}>
                {t("_browse_file")}
              </button>
              <input
                ref={ref}
                multiple={true}
                type="file"
                className="d-none"
                name="files"
                onChange={handleChange}
              />
            </div>
          </div>

          {fileErrors.length > 0 && (
            <div className="error-messages">
              {fileErrors.map((error, index) => (
                <p key={index} style={{ color: "red" }}>
                  {error}
                </p>
              ))}
            </div>
          )}
          <div className="main_box_img">
            <div className="row">
              {formData?.files?.length > 0 &&
                formData?.files?.map(
                  (item, index) =>
                    item?.image && (
                      <div
                        className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6"
                        key={index}
                      >
                        <div className="image_add_prd gallary_images">
                          <FontAwesomeIcon
                            onClick={() => {
                              handleRemoveImage(item.id, index);
                            }}
                            className="text-danger"
                            icon={faTimesCircle}
                          />
                          <img
                            className="preview"
                            src={
                              item.id == ""
                                ? URL.createObjectURL(item.image)
                                : item.image
                            }
                            alt={"image-" + index}
                            key={index}
                          />
                          <input
                            type="radio"
                            className="productradio cursor-pointer cover-image-index"
                            name="cover_image_index"
                            value={index}
                            checked={item.isCover}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    )
                )}
            </div>
            <p className="mt-2">
              <span className="tax-msg ">{t("_note")}: </span>
              {t(
                "_selected_image_will_be_your_main_cover_image_for_the_product."
              )}
            </p>
          </div>

          <div className="btn-last-save">
            <button
              onClick={() => {
                submitData();
              }}
              className="btn-all-save"
            >
              {" "}
              {t("_Save_&_continue")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductUploadPhoto;
