import { useEffect, useState } from "react";
import { useContext } from "react";
import { ProductInformation } from "../../context";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "react-bootstrap/Tooltip";
import { genrateAffliateLink } from "../../actions/detailsAction";
import { handleScroll, successMsg } from "../../actions/customFn";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";
import { TextField, Autocomplete } from "@mui/material";
import CultureAvailability from "./CultureAvailability";
import Map from "../Map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/fontawesome-free-solid";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Popover } from "react-bootstrap";
 
const ProductDetailsNew = () => {
  const { t } = useTranslation();
  const {
    setAffiliateLink,
    setGenerateLink,
    generateLink,
    affiliateLink,
    setAvilableOption,
    DetailsData,
    setFormError,
    errors,
  } = useContext(ProductInformation);
  const profileReducer = useSelector((state) => state.profileReducer);
  const languageReducer = useSelector((state) => state.languageReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [updatedPickUpOption, setUpdatedPickUpOption] = useState([]);
  const homeData = useSelector((state) => state.home);
  const [googleKey, setGoogleKey] = useState("");
  const [showMap, setShowMap] = useState(true);

  useEffect(() => {
    if (homeData) {
      setGoogleKey(homeData?.settings?.google_key);
    }
  }, [homeData]);
  useEffect(() => {
    if (DetailsData.pickup_point.length > 1) {
      const pickupPointNewOption = [
        {
          id: "GO_TO_MEETING_POINT",
          location: t("_i_will_go_to_the_meeting_point"),
        },
        {
          id: "DECIDE_LATER",
          location: t("_i_will_decide_later"),
        },
      ];

      const mergedArray = pickupPointNewOption.concat(DetailsData.pickup_point);
      setUpdatedPickUpOption(mergedArray);
    }
  }, [DetailsData]);

  const renderTooltip = (title) => (
    <Tooltip id="button-tooltip">{title}</Tooltip>
  );

  useEffect(() => {
    if (DetailsData.generated_link != "") {
      setAffiliateLink(DetailsData.generated_link);
      setGenerateLink(true);
    }
  }, []);

  const GenerateProductLink = async () => {
    let affilliate_code = profileReducer.affiliate_code;
    let getUrl = window.location.href;
    const res = getUrl.charAt(getUrl.length - 1);
    if (res == "/") {
      getUrl = getUrl + affilliate_code;
    } else {
      getUrl = getUrl + "/" + affilliate_code;
    }

    dispatch(
      genrateAffliateLink(
        languageReducer,
        navigate,
        setAffiliateLink,
        setGenerateLink,
        getUrl,
        id,
        affilliate_code
      )
    );
  };

  const copyLink = () => {
    navigator.clipboard.writeText(affiliateLink);
    successMsg(t("_Copy_Link_Successfully"));
  };

  const reactRatings = {
    edit: false,
    activeColor: "rgb(252 83 1)",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  const [addressData, setAddressData] = useState({
    meeting_point_address: "",
    end_point_address: "",
    pickup_point_address: "",
  });

  const handleChange = (e, data, key) => {
    let name = key + "_address";
    if (data) {
      const { id } = data;
      setAvilableOption((prev) => ({ ...prev, [key]: id }));
      setAddressData((prev) => ({ ...prev, [name]: data })); //To show Address after selection
      if (key == "pickup_point") {
        errors.pickup_point_error = "";
        setFormError(errors);
      }
    } else {
      setAvilableOption((prev) => ({ ...prev, [key]: "" }));
      setAddressData((prev) => ({ ...prev, [name]: "" }));
    }
  };

  useEffect(() => {
    // If single data comes
    if (DetailsData.meeting_point.length == 1) {
      setAvilableOption((prev) => ({
        ...prev,
        meeting_point: DetailsData.meeting_point[0].id,
      }));
      setAddressData((prev) => ({
        ...prev,
        meeting_point_address: DetailsData.meeting_point[0],
      }));
    }
    if (DetailsData.end_point.length == 1) {
      setAvilableOption((prev) => ({
        ...prev,
        end_point: DetailsData.end_point[0].id,
      }));
      setAddressData((prev) => ({
        ...prev,
        end_point_address: DetailsData.end_point[0],
      }));
    }
    if (DetailsData.pickup_point.length == 1) {
      setAvilableOption((prev) => ({
        ...prev,
        pickup_point: DetailsData.pickup_point[0].id,
      }));
      setAddressData((prev) => ({
        ...prev,
        pickup_point_address: DetailsData.pickup_point[0],
      }));
    }
  }, []);

  // ==========Google map code=========

  const fitBounds = async (map) => {
    let bounds = new window.google.maps.LatLngBounds();
    let isSingleLocation = false;
    if (map != null) {
      DetailsData?.attraction.forEach((marker) => {
        if (marker.attraction_latitude) {
          bounds.extend(
            new window.google.maps.LatLng(
              marker.attraction_latitude,
              marker.attraction_longitude
            )
          );
        }
      });

      if (DetailsData.attraction.length == 1) {
        isSingleLocation = true;
      }

      if (isSingleLocation == true) {
        let zoomLevel = 0.01; //Increase value : Zoom-out, Decrease value:Zoom In
        const singleLocation = bounds.getCenter();
        bounds.extend(
          new window.google.maps.LatLng(
            singleLocation.lat() + zoomLevel,
            singleLocation.lng() + zoomLevel
          )
        );
        bounds.extend(
          new window.google.maps.LatLng(
            singleLocation.lat() - zoomLevel,
            singleLocation.lng() - zoomLevel
          )
        );
      }

      await map.fitBounds(bounds);
    }
  };

  const handleMapMounted = (map) => {
    if (map != null) {
      fitBounds(map);
    }
  };

  // ==========Google map code=========

  useEffect(() => {
    let isAvailable = false;
    DetailsData?.attraction.forEach((obj) => {
      if (obj.attraction_longitude != "" && obj.attraction_latitude != "") {
        isAvailable = true;
      }
    });

    setShowMap(isAvailable);
  }, [DetailsData]);

  return (
    <div>
      {DetailsData && googleKey && (
        <>
          <div className="title_with_review">
            <h2>
              {DetailsData.description.title != "" &&
                DetailsData?.description?.title.charAt(0)?.toUpperCase() +
                  DetailsData?.description?.title?.slice(1)}
            </h2>
            <div className="review_subtitle">
              <button type="button" className="review_link">
                <div className="review_star_list">
                  <ReactStars
                    style={{ cursor: "pointer !important" }}
                    classNames="star-class"
                    size={16}
                    value={Number(DetailsData.ratings)}
                    {...reactRatings}
                  />
                </div>
                <p
                  className="cursor-pointer"
                  onClick={() => handleScroll("review-div", 100)}
                >
                  ({DetailsData.total_review} {t("_reviews")} )
                </p>

                {/* <div className="divide_reviewbadge"></div>
                        {
                            DetailsData.show_badge &&
                            <div className="badgediv" dangerouslySetInnerHTML={{ __html: DetailsData.show_badge }}>
                            </div>
                        } */}
              </button>
              <span className="divider_line"></span>
              <span className="location">
                {DetailsData.city}
                {DetailsData.city && (DetailsData.state || DetailsData.country)
                  ? ","
                  : ""}
                {DetailsData.state}
                {DetailsData.city || (DetailsData.state && DetailsData.country)
                  ? ","
                  : ""}
                {DetailsData.country}
              </span>

              <span className="divider_line"></span>
              <span>{DetailsData.trip_details}</span>
            </div>
            {profileReducer.user_type == "Affiliate" && (
              <div className="generate_link_box mt-3 mb-2">
                {generateLink ? (
                  <p className="font-weight-normal m-0 text-body">
                    {affiliateLink}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip("Copy Link")}
                    >
                      <a
                        href={void 0}
                        className="float-right mr-3"
                        type="button"
                        onClick={copyLink}
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </a>
                    </OverlayTrigger>
                  </p>
                ) : (
                  <button
                    type="button"
                    onClick={GenerateProductLink}
                    className="generate_link_btn "
                  >
                    {t("_genrate_product_affilate_link")}
                  </button>
                )}
              </div>
            )}
          </div>

          <div
            className="product_attributes_list_container"
            id="hidefloating-nav-div"
            data-section
          >
            <ul className="product_attributes_list">
              <li className="item">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                >
                  <path d="M12.5 6.75a.75.75 0 00-1.5 0v5.5c0 .41.34.75.75.75h4.75a.75.75 0 000-1.5h-4V6.75z"></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2a10 10 0 100 20 10 10 0 000-20zM3.5 12a8.5 8.5 0 1117 0 8.5 8.5 0 01-17 0z"
                  ></path>
                </svg>
                <p>{DetailsData.duration_text}</p>
              </li>
              <li className="item">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                >
                  <path d="M17 14a1 1 0 100-2 1 1 0 000 2zM8 13a1 1 0 11-2 0 1 1 0 012 0zM10.25 14a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z"></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.91 9.24l.03.1c.63.4 1.06 1.1 1.06 1.91v8.5c0 .97-.78 1.75-1.75 1.75h-1.5c-.97 0-1.75-.78-1.75-1.75V18.5H8v1.25c0 .97-.78 1.75-1.75 1.75h-1.5c-.97 0-1.75-.78-1.75-1.75v-8.5c0-.8.43-1.52 1.06-1.91l.03-.1.2-.74h-.54a.75.75 0 010-1.5h.93l.48-1.77A3 3 0 018.06 3h7.89a3 3 0 012.9 2.23L19.31 7h.93a.75.75 0 010 1.5h-.53l.2.74zM5.71 9l.9-3.39A1.5 1.5 0 018.05 4.5h7.9c.67 0 1.27.46 1.44 1.11L18.3 9H5.7zm-.46 1.5h13.5c.41 0 .75.34.75.75V17h-15v-5.75c0-.41.34-.75.75-.75zm12.25 8v1.25c0 .14.11.25.25.25h1.5c.14 0 .25-.11.25-.25V18.5h-2zm-13 0h2v1.25c0 .14-.11.25-.25.25h-1.5a.25.25 0 01-.25-.25V18.5z"
                  ></path>
                </svg>
                <p>{DetailsData.pickup_offered}</p>
              </li>
              <li className="item">
                {/* <div className="divide_reviewbadge"></div> */}
                {DetailsData.show_badge && (
                  <div
                    className="badgediv badgediv-new"
                    dangerouslySetInnerHTML={{ __html: DetailsData.show_badge }}
                  ></div>
                )}
              </li>
              {DetailsData.language_accomodate.length > 0 && (
                <li className="item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="24"
                    height="24"
                    x="0"
                    y="0"
                    viewBox="0 0 32 32"
                  >
                    <g>
                      <g data-name="Layer 2">
                        <path
                          d="M2.87 28.56A1.88 1.88 0 0 1 1 26.69V11.31a3.88 3.88 0 0 1 3.87-3.87h18.25A3.88 3.88 0 0 1 27 11.31v10.25a3.88 3.88 0 0 1-3.87 3.88H7.91a.82.82 0 0 0-.48.16L4 28.19a1.89 1.89 0 0 1-1.13.37zm2-19.12A1.88 1.88 0 0 0 3 11.31v15.13L6.23 24a2.84 2.84 0 0 1 1.68-.56h15.21A1.88 1.88 0 0 0 25 21.56V11.31a1.88 1.88 0 0 0-1.87-1.87z"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                        ></path>
                        <circle
                          cx="17.94"
                          cy="16.53"
                          r="1"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                        ></circle>
                        <circle
                          cx="14.06"
                          cy="16.53"
                          r="1"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                        ></circle>
                        <circle
                          cx="10.06"
                          cy="16.53"
                          r="1"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                        ></circle>
                        <path
                          d="M30 18.44a1 1 0 0 1-1-1V7.31a1.88 1.88 0 0 0-1.87-1.87H7a1 1 0 0 1-1-1 1 1 0 0 1 1-1h20.13A3.88 3.88 0 0 1 31 7.31v10.13a1 1 0 0 1-1 1z"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                        ></path>
                      </g>
                    </g>
                  </svg>
                  <p>
                    {t("_Offered_in")} :{" "}
                    {DetailsData.language_accomodate.length == 1 ? (
                      DetailsData.language_accomodate[0]
                    ) : DetailsData.language_accomodate.length == 2 ? (
                      <>
                        {DetailsData.language_accomodate[0]} {t("_and")}{" "}
                        {DetailsData.language_accomodate[1]}
                      </>
                    ) : DetailsData.language_accomodate.length > 2 ? (
                      <>
                        {" "}
                        {DetailsData.language_accomodate[0]} {t("_and")}{" "}
                        <OverlayTrigger
                          trigger="focus"
                          placement="top"
                          overlay={
                            <Popover
                              id="popover-positioned-top"
                              title="Popover top"
                              className="language-accomodate-popover"
                            >
                              <label className="offered-in-title">
                                {t("_Offered_in")} :
                              </label>
                              <p>
                                {DetailsData.language_accomodate.map(
                                  (value, index) => {
                                    return (
                                      <span
                                        key={index}
                                        className="language-list-in-popover"
                                      >
                                        {value}
                                        {DetailsData.language_accomodate
                                          .length -
                                          1 !=
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </p>
                            </Popover>
                          }
                        >
                          <button className="language-accomodate-others">
                            {DetailsData.language_accomodate.length - 1}{" "}
                            {t("_more")}
                          </button>
                        </OverlayTrigger>
                      </>
                    ) : (
                      DetailsData.language_accomodate[0]
                    )}
                  </p>
                </li>
              )}
            </ul>
          </div>

          <CultureAvailability />
          <div className="overview_wrapper" id="overview-div" data-section>
            <h2 className="title_wrapper"> {t("_Overview")}</h2>
            <div className="description">
              <p
                dangerouslySetInnerHTML={{
                  __html: DetailsData.description.description,
                }}
              ></p>
            </div>
          </div>
          <div className="what_include_wrapper" id="included-div" data-section>
            <div className="content_list_wrapper">
              <div className="row">
                {DetailsData?.inclusion.length > 0 && (
                  <div className="col-lg-6 col-md-6 col-12">
                    <h2 className="title_wrapper">{t("_What's_Included")}</h2>
                    <ul className="include_list">
                      {DetailsData?.inclusion.map((value, index) => {
                        return (
                          <li className="includes" key={index}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M18.03 3.97c.3.3.3.77 0 1.06l-10.5 10.5a.75.75 0 01-1.1-.03l-4-4.5a.75.75 0 011.13-1l3.47 3.9 9.94-9.93c.3-.3.77-.3 1.06 0z"
                              ></path>
                            </svg>
                            <p>{value}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {DetailsData?.exclusion.length > 0 && (
                  <div className="col-lg-6 col-md-6 col-12">
                    <h2 className="title_wrapper">
                      {t("_What's_not_Included")}
                    </h2>
                    <ul className="include_list">
                      {DetailsData?.exclusion.map((value, index) => {
                        return (
                          <li className="includes" key={index}>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M3.97 14.97a.75.75 0 101.06 1.06L10 11.06l4.97 4.97a.75.75 0 101.06-1.06L11.06 10l4.97-4.97a.75.75 0 00-1.06-1.06L10 8.94 5.03 3.97a.75.75 0 00-1.06 1.06L8.94 10l-4.97 4.97z"></path>
                            </svg>
                            <p>{value}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className="meet_pickup_wrapper"
            id="meeting-and-pickup-div"
            data-section
          >
            <h2 className="title_wrapper"> {t("_Meeting_And_Pickup")}</h2>
            <div className="departure_container">
              {DetailsData.meeting_point?.length > 0 ? (
                <div className="meeting_points sub_sec">
                  <h3 className="inner_title"> {t("_Meeting_point")}</h3>
                  {DetailsData.meeting_point.length > 1 && (
                    <div
                      className="autocomplete-product-details mb-3"
                      name="meeting_point"
                    >
                      <Autocomplete
                        options={DetailsData.meeting_point}
                        getOptionLabel={(option) => option.location}
                        onChange={(e, data) =>
                          handleChange(e, data, "meeting_point")
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </div>
                  )}

                  {addressData.meeting_point_address && (
                    <>
                      <div className="mt-2">
                        <p className="address-location">
                          {" "}
                          {addressData.meeting_point_address.location}
                        </p>
                      </div>
                      <div className="map_link">
                        <button
                          type="button"
                          onClick={() =>
                            window.open(
                              `//google.com/maps/?q=${addressData.meeting_point_address.latitude},${addressData.meeting_point_address.longitude}`,
                              "_blank"
                            )
                          }
                        >
                          {t("_Open_in_Map")}
                        </button>
                      </div>
                      <p
                        className="instruction"
                        dangerouslySetInnerHTML={{
                          __html: addressData.meeting_point_address.description,
                        }}
                      ></p>
                    </>
                  )}
                </div>
              ) : (
                "You can head directly to the meeting point."
              )}

              {DetailsData.pickup_point?.length > 0 && (
                <div className="pickup_points sub_sec" id="pickup-point-div">
                  <h3 className="inner_title">{t("_Pickup_points")}</h3>

                  <div className="locationSearchBar">
                    {DetailsData.pickup_point.length > 1 && (
                      <>
                        <div className="select_pickup">
                          {t("_Select_a_pickup_point")}
                        </div>
                        <div
                          className="autocomplete-product-details mb-3"
                          name="pickup_point"
                        >
                          <Autocomplete
                            options={updatedPickUpOption}
                            getOptionLabel={(option) => option.location}
                            onChange={(e, data) =>
                              handleChange(e, data, "pickup_point")
                            }
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        </div>
                      </>
                    )}
                    {addressData.pickup_point_address.longitude !=
                      undefined && (
                      <>
                        <div className="mt-2">
                          <p className="address-location">
                            {" "}
                            {addressData.pickup_point_address.location}
                          </p>
                        </div>
                        <div className="map_link">
                          <button
                            type="button"
                            onClick={() =>
                              window.open(
                                `//google.com/maps/?q=${addressData.pickup_point_address.latitude},${addressData.pickup_point_address.longitude}`,
                                "_blank"
                              )
                            }
                          >
                            {t("_Open_in_Map")}
                          </button>
                        </div>
                        <p
                          className="instruction"
                          dangerouslySetInnerHTML={{
                            __html:
                              addressData.pickup_point_address.description,
                          }}
                        ></p>
                      </>
                    )}
                  </div>
                </div>
              )}

              {DetailsData.end_point.length > 0 ? (
                <div className="end_point sub_sec" id="endpoint-point-div">
                  <h3 className="inner_title">{t("_End_Point")}</h3>

                  {/* {
                                      value.end_point.location == undefined
                                        ?
                                        <NavLink
                                          target="__blank"
                                          to={`https://www.google.com/maps/?q=${value.meeting_point.latitude},${value.meeting_point.longitude}`}
                                        >
                                          {value.meeting_point.location}
                                        </NavLink>
                                        :
                                        <NavLink
                                          target="__blank"
                                          to={`https://www.google.com/maps/?q=${value.end_point.latitude},${value.end_point.longitude}`}
                                        >
                                          {value.end_point.location}
                                        </NavLink>
                                    } */}

                  <div name="end_point">
                    {DetailsData.end_point?.length > 1 && (
                      <div className="autocomplete-product-details mb-3">
                        <Autocomplete
                          options={DetailsData.end_point}
                          getOptionLabel={(option) => option.location}
                          onChange={(e, data) =>
                            handleChange(e, data, "end_point")
                          }
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </div>
                    )}

                    {addressData.end_point_address && (
                      <>
                        <div className="mt-2">
                          <p className="address-location">
                            {" "}
                            {addressData.end_point_address.location}
                          </p>
                        </div>
                        <div className="map_link">
                          <button
                            type="button"
                            onClick={() =>
                              window.open(
                                `//google.com/maps/?q=${addressData.end_point_address.latitude},${addressData.end_point_address.longitude}`,
                                "_blank"
                              )
                            }
                          >
                            {t("_Open_in_Map")}
                          </button>
                        </div>
                        <p
                          className="instruction"
                          dangerouslySetInnerHTML={{
                            __html: addressData.end_point_address.description,
                          }}
                        ></p>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                DetailsData.end_point_details != "" && (
                  <div className="pickup_detail sub_sec">
                    <h3 className="inner_title">{t("_End_Point_details")}</h3>
                    <p className="logisticsAdditionalInfo__2QOY">
                      {DetailsData.end_point_details}
                    </p>
                  </div>
                )
              )}

              {DetailsData.pickup_details != "" && (
                <div className="pickup_detail sub_sec">
                  <h3 className="inner_title">{t("_Pickup_details")}</h3>
                  <p className="logisticsAdditionalInfo__2QOY">
                    {DetailsData.pickup_details}
                  </p>
                </div>
              )}
            </div>
          </div>

          {(DetailsData.what_to_expect?.length > 0 ||
            DetailsData.attraction?.length > 0) && (
            <div id="what-to-expect-div" data-section>
              <div
                className="what_to_expect_wrapper "
                data-automation="itinerary-section"
              >
                <h2 className="title_wrapper"> {t("_What_To_Expect")}</h2>
                <div className="step_container">
                  <div className="items">
                    {DetailsData.what_to_expect.map((value, index) => {
                      return (
                        value != "" && (
                          <div className="item_start_except" key={index}>
                            <div className="details__ntls">
                              <p
                                dangerouslySetInnerHTML={{ __html: value }}
                              ></p>
                            </div>
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="map-both-side row">
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                  <div
                    className="what_to_expect_wrapper what_to_expect_wrapper_attraction"
                    data-automation="itinerary-section"
                  >
                    {/* <h2 className="title_wrapper">Attraction</h2> */}
                    <div className="multiple-product-main">
                      <div className="step_container">
                        <div className="items">
                          {DetailsData.attraction?.map((value, index) => {
                            var nextAttraction = 0;

                            if (
                              DetailsData.attraction[index + 1] != undefined
                            ) {
                              if (
                                DetailsData.attraction[index + 1][
                                  "attraction_transport_by"
                                ] != undefined &&
                                DetailsData.attraction[index + 1][
                                  "attraction_transport_by"
                                ] != "" &&
                                DetailsData.attraction[index + 1][
                                  "attraction_transport_by"
                                ] != "no_transport"
                              ) {
                                nextAttraction = 1;
                              }
                            }
                            return (
                              value.attraction_title != "" && (
                                <div
                                  className={
                                    nextAttraction == 1
                                      ? "item_start"
                                      : "line_start"
                                  }
                                  key={index}
                                >
                                  <div className="all-data-details">
                                    <div type="button" className="image_block">
                                      <img
                                        className="image-data-main"
                                        src={value.attraction_icon}
                                        alt=""
                                      />
                                    </div>
                                    {value.attraction_transport_by != "" &&
                                      value.attraction_title == "" && (
                                        <div className="inner-data">
                                          <h2>
                                            {value.attraction_transport_by
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                              value?.attraction_transport_by?.slice(
                                                1
                                              )}
                                          </h2>
                                          {value.attraction_transport_time !=
                                            "" &&
                                            value.attraction_transport_time_type !=
                                              "" && (
                                              <p className="attraction_p">
                                                (
                                                {
                                                  value.attraction_transport_time
                                                }{" "}
                                                {
                                                  value.attraction_transport_time_type
                                                }{" "}
                                                )
                                              </p>
                                            )}
                                        </div>
                                      )}
                                    <div className="details__ntls">
                                      <h4 className="step_name">
                                        {value?.attraction_title}
                                        {value.is_pass_by_without_stopping !=
                                          undefined &&
                                          value.is_pass_by_without_stopping ==
                                            "true" && (
                                            <span className="pass-by">
                                              ({t("_Pass_By")})
                                            </span>
                                          )}
                                      </h4>
                                      <p className="inner_title">
                                        {value.description}
                                        <br />
                                        {value.attraction_time &&
                                          value.attraction_time_type &&
                                          value.is_pass_by_without_stopping !=
                                            "true" && (
                                            <span className="attraction-time-type">
                                              {" "}
                                              {value.attraction_time}{" "}
                                              {value.attraction_time_type}
                                              {value.include_price_tour && (
                                                <>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="attraction-time-dot-svg"
                                                    height="7"
                                                    width="7"
                                                    viewBox="0 0 512 512"
                                                  >
                                                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
                                                  </svg>

                                                  {value.include_price_tour}
                                                </>
                                              )}
                                            </span>
                                          )}
                                      </p>
                                    </div>
                                  </div>
                                  {/* 
                              <div className="all-inner-data">
                                <div className="inner-data-img">
                                  <img src={value.attraction_middle_icon} />
                                </div>

                                <div className="inner-data">
                                  <h2>{value.attraction_transport_by}</h2>
                                  <p>
                                    ({value.attraction_transport_time}{" "}
                                    {value.attraction_transport_time_type} )
                                  </p>
                                </div>
                              </div> */}
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {DetailsData?.attraction?.length > 0 && showMap == true && (
                  <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                    <div className="map-inner">
                      <div className="google-map-code vishal">
                        <Map
                          center={{ lat: 22.7527421, lng: 75.88371599999999 }}
                          zoom={12}
                          mapName="attraction"
                          places={{
                            multiple: DetailsData.attraction,
                          }}
                          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `300px` }} />}
                          mapElement={
                            <div
                              style={{ height: `100%`, borderRadius: `2%` }}
                            />
                          }
                          onMapMounted={(map) => handleMapMounted(map)}

                          // onBoundsChanged={(map) => handleBoundsChanged(map, "attraction")}
                          // handleGoogleAddress={handleGoogleAddress}
                          // setIsDragged={setIsDragged}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="product_additionaldetail">
            <div className="row">
              <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12">
                {DetailsData.additional_info?.length > 0 && (
                  <div
                    className="addition_info_wrapper"
                    id="addition_info-div"
                    data-section
                  >
                    <h2 className="title_wrapper">
                      {t("_accessibility_info")}
                    </h2>
                    <div className="info_list_wrapper">
                      <div className="row">
                        {DetailsData.additional_info.map((value, index) => {
                          return (
                            <div
                              className="col-lg-12 col-md-12 col-12"
                              key={index}
                            >
                              <ul className="info_list">
                                <li>{value}</li>
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12">
                {DetailsData.additional_info_restriction?.length > 0 && (
                  <div
                    className="addition_info_wrapper"
                    id="addition_info-div"
                    data-section
                  >
                    <h2 className="title_wrapper">
                      {t("_additional_inforestriction")}
                    </h2>
                    <div className="info_list_wrapper">
                      <div className="row">
                        {DetailsData.additional_info_restriction.map(
                          (value, index) => {
                            return (
                              <div
                                className="col-lg-12 col-md-12 col-12"
                                key={index}
                              >
                                <ul className="info_list">
                                  <li>{value}</li>
                                </ul>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>


          {(DetailsData.mendatory_items?.length > 0 || DetailsData?.not_allowed_items?.length > 0 || DetailsData?.pre_booking_info) && (
            <div className="product_extra_information" id ="product_extra_information">

              <h2 className="title_wrapper mb-4">
                Important information
              </h2>
          
              <div className="row">
              <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12">
                {DetailsData.mendatory_items?.length > 0 && (

                    <div className="what_to_bring_section mb-4">
                      <h5>What to bring</h5>
                      <div className="info_list_wrapper">
                        <div className="row">
                          {DetailsData?.mendatory_items?.map((value, index) => {
                            return (
                              <div
                                className="col-lg-12 col-md-12 col-12"
                                key={index}
                              >
                                <ul className="info_list">
                                  <li>{value}</li>
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                )}
              </div>
              </div>

              <div className="row">
                <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12">
                  {DetailsData?.not_allowed_items?.length > 0 && (
                    
                    <div className="not_allowed_item mb-4">
                      <h5>What's not allowed</h5>
                      <div className="info_list_wrapper">
                        <div className="row">
                          {DetailsData?.not_allowed_items?.map(
                            (value, index) => {
                              return (
                                <div
                                  className="col-lg-12 col-md-12 col-12"
                                  key={index}
                                >
                                  <ul className="info_list">
                                    <li>{value}</li>
                                  </ul>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12">
                  {DetailsData?.pre_booking_info && (
                    <div className="pre_booking_info mb-4">
                      <h5>To know before</h5>
                      <div className="pre_booking_info_wrapper">
                          <p>{DetailsData?.pre_booking_info}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
                
            </div>
          )}

          {DetailsData.policy_type_data && (
            <div
              className="cancellation_policy_wrapper"
              id="cancellation-policy-div"
              data-section
            >
              <h2 className="title_wrapper">{t("_Cancellation_Policy")}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: DetailsData.policy_type_data,
                }}
              ></div>
              {/* {DetailsData.policy_type == "STANDARD" ? (
                <>
                  <p className="headerPolicy">
                    You can cancel up to 24 hours in advance of the experience
                    for a full refund.
                  </p>
                  <div className="policy_list_wrapper">
                    <div className="row">
                      <>
                        <div className="col-lg-6 col-md-6 col-12">
                          <ul className="policy_list">
                            <li>
                              For a full refund, you must cancel at least 24
                              hours before the experience’s start time.
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <ul className="policy_list">
                            <li>
                              Cut-off times are based on the experience’s local
                              time.
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <ul className="policy_list">
                            <li>
                              If you cancel less than 24 hours before the
                              experience’s start time, the amount you paid will
                              not be refunded.
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <ul className="policy_list">
                            <li>
                              This experience requires a minimum number of
                              travelers. If it’s canceled because the minimum
                              isn’t met, you’ll be offered a different
                              date/experience or a full refund.
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <ul className="policy_list">
                            <li>
                              Any changes made less than 24 hours before the
                              experience’s start time will not be accepted.
                            </li>
                          </ul>
                        </div>
                      </>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className="headerPolicy">
                    This experience is non-refundable and cannot be changed for
                    any reason. If you cancel or ask for an amendment, the
                    amount you paid will not be refunded.
                  </p>
                  <div className="policy_list_wrapper">
                    <div className="row">
                      <>
                        <div className="col-lg-6 col-md-6 col-12">
                          <ul className="policy_list">
                            <li>
                              This experience requires a minimum number of
                              travelers. If it’s canceled because the minimum
                              isn’t met, you’ll be offered a different
                              date/experience or a full refund.
                            </li>
                          </ul>
                        </div>
                      </>
                    </div>
                  </div>
                </>
              )} */}
              {/* <div className="learn-more-div">
                                <button type="button"
                                    className="learn-more-btn"
                                >
                                    Learn more
                                </button>
                                <p>
                                {" "}
                                    about cancellations.
                                </p>
                            </div> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductDetailsNew;
