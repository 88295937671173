import React, { useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { ProductBuilderContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import InclusionModal from "./InclusionEnclusionComponents/InclusionModal";
import ExclusionModal from "./InclusionEnclusionComponents/ExclusionModal";
import { getArray, images, startSpinLoader } from "../../actions/customFn";
import { uplodProductNew } from "../../actions/productBuilderAction";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import AddNewCategoryModal from "./InclusionEnclusionComponents/AddNewCategoryModal";
import Swal from "sweetalert2";

const InclusionExclusion = (props) => {
  const { t } = useTranslation();
  const { tour_id } = useParams();
  const [inclusionModalShow, setInclusionModalShow] = useState(false);
  const [exclusionModalShow, setExclusionModalShow] = useState(false);

  const [categoryAddModal, setCategoryAddModal] = useState(false);

  const {
    productType,
    setTourId,
    getData,
    setSpinLoader,
    saveAndExit,
    exitRoute,
    productInfo,
    languageIdSelected,
    isConfirmationModal,
    setIsConfirmationModal
  } = useContext(ProductBuilderContext);
  const languageReducer = useSelector((state) => state.languageReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const [getEditData, setEditData] = useState([]);
  const [getInclusion, setInclusion] = useState([]);
  const [getExclusionKey, setExclusionKey] = useState("");
  const [getInclusionKey, setInclusionKey] = useState("");
  const [getExclusion, setExclusion] = useState([]);
  const [getCategory, setCategory] = useState([]);
  const [formErrors, setFormError] = useState({});
  const [newCategoryType, setNewCategoryType] = useState("");
  const [isChange, setIsChange] = useState(0);

  const [inclusionData, setInclusionData] = useState({
    inclusion_id: "",
    inclusion_parent: "",
    inclusion_parent_title: "",
    inclusion_title: "",
    inclusion_child_title: "",
    is_edit: 1,
  });

  const [exclusionData, setExclusionData] = useState({
    exclusion_id: "",
    exclusion_parent: "",
    exclusion_parent_title: "",
    exclusion_title: "",
    exclusion_child_title: "",
    is_edit: 1,
  });

  const errors = {};

  // get_child
  useEffect(() => {
    if (languageReducer != "") {
      getData("eight", setEditData);
    }else{
      setIsConfirmationModal(false);
    }
  }, [languageReducer, languageIdSelected]);

  useEffect(() => {
    if (getEditData != undefined) {
      const { inclusion_exclusion_parent, product_data } = getEditData;
      setCategory(inclusion_exclusion_parent);
      setExclusion(
        product_data?.exclusion != undefined ? product_data?.exclusion : []
      );
      setInclusion(
        product_data?.inclusion != undefined ? product_data?.inclusion : []
      );

      if(product_data?.is_add_inclusion){

        setIsChange(product_data?.isChange);
      }
    }
  }, [getEditData]);

  const handleRemoveInclusion = (key) => {
    const newData = [...getInclusion];
    newData.splice(key, 1);
    setInclusion(newData);
    if(getEditData?.product_data?.is_add_inclusion){
      setIsChange(1);
      setIsConfirmationModal(true);
    }
  };

  const handleRemoveExclusion = (key) => {
    const newData = [...getExclusion];
    newData.splice(key, 1);
    setExclusion(newData);
    if(getEditData?.product_data?.is_add_inclusion){
      setIsChange(1);
      setIsConfirmationModal(true);
    }
  };

  const validateFormData = () => {
    // let errorMessage = "This field required";
    // if (tourData.title === "") {
    //   errors.title = errorMessage;
    // }
    if (getInclusion.length == 0) {
      errors.inclusion_data = t("_atleast_one_inclusion_is_required");
    }
    setFormError(errors);
  };

  const SubmitDataFn = (exit)=>{
    startSpinLoader("cover-spin");
    const sendData = {
      tourId: tour_id,
      product_type: productType,
      step: "eight",
      language: productInfo.productLanguageId,
      isChange: isChange,

      inclusion_id: getArray(getInclusion, "inclusion_id"),
      inclusion_title: getArray(getInclusion, "inclusion_title"),
      inclusion_parent: getArray(getInclusion, "inclusion_parent"),
      exclusion_id: getArray(getExclusion, "exclusion_id"),
      exclusion_title: getArray(getExclusion, "exclusion_title"),
      exclusion_parent: getArray(getExclusion, "exclusion_parent"),
    };
    let nextRoute = exit || "unique-product";
    if (languageReducer !== "") {
      dispatch(
        uplodProductNew(
          languageReducer,
          setTourId,
          navigate,
          sendData,
          nextRoute,
          "",
          "",
          "",
          "",
          "",
          "",
          setSpinLoader
        )
      );
    }
  }

  const handleSubmit = (e, exit) => {
    if (e) {
      e.preventDefault();
    }
    validateFormData();
    if (Object.keys(errors).length === 0) {
      if (isConfirmationModal) {
        Swal.fire({
          title: t("_are_you_sure"),
          text: t(
            "_your_product_modification_require_approval_can_take_up_to_24_hours_for_revision_your_product"
          ),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "rgb(155 176 199)",
          cancelButtonColor: "rgb(227 46 46)",
          confirmButtonText: "Yes",
        }).then((res) => {
          if (res.isConfirmed === true) {
            SubmitDataFn(exit)
          }
        });
      }else{
        SubmitDataFn(exit)
      }
    }
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      handleSubmit("", exitRoute);
    } else {
      isFirstRender.current = false;
    }
  }, [saveAndExit]);

  const [completeList, setCompleteList] = useState([]);
  useEffect(() => {
    setCompleteList(getInclusion.concat(getExclusion));
  }, [getInclusion, getExclusion]);

  return (
    <>
      <div className="meet-pickup-main">
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="inclusion_exclusion activity_productdetail">
            <h1>
              {t("_What_is_and_isn't_included?")}{" "}
              <Tooltip title="Help Center" placement="right">
                <button className="togle_questionbtn">
                  <img src={images["question_togle.png"]} alt="" />
                </button>
              </Tooltip>{" "}
            </h1>
            <p>
              {t(
                "_Let_travelers_know_exactly_what_they're_paying_for_List_all_inclusions_like_food_and_drinks,_special_equipment,_and_admission_fees."
              )}
            </p>
            <div className="product-info-box">
              <div className="icon-svg-info">
                <img src={images["excl.svg"]} alt="" />
              </div>

              <div className="meet-box-content">
                <p>{t("_make_it_clear_inc")}</p>
              </div>
            </div>

            <div>
              <h5>{t("_What's_Included")}</h5>

              {getInclusion != undefined &&
                getInclusion.length > 0 &&
                getInclusion.map((value, index) => {
                  return (
                    <div className="inclueded_partsection" key={index}>
                      <p>
                        {value.inclusion_child_title != ""
                          ? value.inclusion_child_title
                          : value.inclusion_parent_title}
                      </p>

                      <p>
                        {value?.is_edit == 1 && (
                          <span
                            className="cursor-pointer"
                            onClick={(e) => {
                              setInclusionKey(index);
                              setInclusionModalShow(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M20.6626 6.64062L17.3762 3.33737C17.159 3.1213 16.8651 3 16.5588 3C16.2524 3 15.9585 3.1213 15.7413 3.33737L4.09038 14.9715L3.02662 19.5625C2.98992 19.7303 2.99118 19.9042 3.0303 20.0715C3.06942 20.2388 3.14542 20.3952 3.25273 20.5294C3.36005 20.6635 3.49598 20.772 3.65059 20.8469C3.80519 20.9217 3.97458 20.9611 4.14637 20.9622C4.22641 20.9703 4.30707 20.9703 4.38711 20.9622L9.02846 19.8984L20.6626 8.27545C20.8787 8.05828 21 7.76439 21 7.45804C21 7.15169 20.8787 6.8578 20.6626 6.64062ZM8.46859 18.8907L4.11837 19.8032L5.10935 15.537L13.8266 6.85338L17.1858 10.2126L8.46859 18.8907ZM17.936 9.4008L14.5768 6.04156L16.5252 4.1044L19.8284 7.46364L17.936 9.4008Z"
                                fill="#fc5301"
                              />
                            </svg>
                          </span>
                        )}
                        <span
                          className="cursor-pointer"
                          onClick={(e) => {
                            handleRemoveInclusion(index);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                              fill="#fc5301"
                            />
                          </svg>
                        </span>
                      </p>
                    </div>
                  );
                })}
              <p className="form_error">{formErrors?.inclusion_data}</p>
              <button
                type="button"
                className="trasnparent_buton mt-2"
                onClick={() => {
                  setInclusionKey("");
                  setInclusionModalShow(true);
                }}
              >
                + {t("_Add_an_inclusion")}
              </button>
            </div>

            <div>
              <h5>{t("_What's_exclude")}</h5>
              {getExclusion != undefined &&
                getExclusion.length > 0 &&
                getExclusion.map((value, index) => {
                  return (
                    <div className="inclueded_partsection" key={index}>
                      <p>
                        {value.exclusion_child_title != ""
                          ? value.exclusion_child_title
                          : value.exclusion_parent_title}
                      </p>

                      <p>
                        {value?.is_edit == 1 && (
                          <span
                            className="cursor-pointer"
                            onClick={(e) => {
                              setExclusionKey(index);
                              setExclusionModalShow(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M20.6626 6.64062L17.3762 3.33737C17.159 3.1213 16.8651 3 16.5588 3C16.2524 3 15.9585 3.1213 15.7413 3.33737L4.09038 14.9715L3.02662 19.5625C2.98992 19.7303 2.99118 19.9042 3.0303 20.0715C3.06942 20.2388 3.14542 20.3952 3.25273 20.5294C3.36005 20.6635 3.49598 20.772 3.65059 20.8469C3.80519 20.9217 3.97458 20.9611 4.14637 20.9622C4.22641 20.9703 4.30707 20.9703 4.38711 20.9622L9.02846 19.8984L20.6626 8.27545C20.8787 8.05828 21 7.76439 21 7.45804C21 7.15169 20.8787 6.8578 20.6626 6.64062ZM8.46859 18.8907L4.11837 19.8032L5.10935 15.537L13.8266 6.85338L17.1858 10.2126L8.46859 18.8907ZM17.936 9.4008L14.5768 6.04156L16.5252 4.1044L19.8284 7.46364L17.936 9.4008Z"
                                fill="#fc5301"
                              />
                            </svg>
                          </span>
                        )}

                        <span
                          className="cursor-pointer"
                          onClick={(e) => {
                            handleRemoveExclusion(index);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                              fill="#fc5301"
                            />
                          </svg>
                        </span>
                      </p>
                    </div>
                  );
                })}

              <button
                type="button"
                className="trasnparent_buton mt-2"
                onClick={() => {
                  setExclusionKey("");
                  setExclusionModalShow(true);
                }}
              >
                +{t("_add_an_exclusion")}
              </button>
            </div>

            <div className="btn-last-save">
              <button type="submit" className="btn-all-save">
                {t("_Save_&_continue")}
              </button>
            </div>
          </div>
        </form>
        <InclusionModal
          inclusionModalShow={inclusionModalShow}
          setInclusionModalShow={setInclusionModalShow}
          getCategory={getCategory}
          getInclusionKey={getInclusionKey}
          setInclusionKey={setInclusionKey}
          getInclusion={getInclusion}
          setInclusion={setInclusion}
          setMainFormError={setFormError}
          completeList={completeList}
          categoryAddModal={categoryAddModal}
          setCategoryAddModal={setCategoryAddModal}
          inclusionData={inclusionData}
          setInclusionData={setInclusionData}
          setNewCategoryType={setNewCategoryType}
          setIsChange={setIsChange}
          setIsConfirmationModal={setIsConfirmationModal}
          product_data = {getEditData?.product_data}
          
        />

        <ExclusionModal
          exclusionModalShow={exclusionModalShow}
          setExclusionModalShow={setExclusionModalShow}
          getCategory={getCategory}
          getExclusionKey={getExclusionKey}
          setExclusionKey={setExclusionKey}
          getExclusion={getExclusion}
          setExclusion={setExclusion}
          completeList={completeList}
          categoryAddModal={categoryAddModal}
          setCategoryAddModal={setCategoryAddModal}
          exclusionData={exclusionData}
          setExclusionData={setExclusionData}
          setNewCategoryType={setNewCategoryType}
          setIsChange={setIsChange}
          setIsConfirmationModal={setIsConfirmationModal}
          product_data = {getEditData?.product_data}
        />
        <AddNewCategoryModal
          categoryAddModal={categoryAddModal}
          setCategoryAddModal={setCategoryAddModal}
          newCategoryType={newCategoryType}
          setNewCategoryType={setNewCategoryType}
          getInclusion={getInclusion}
          setInclusion={setInclusion}
          getExclusion={getExclusion}
          setExclusion={setExclusion}
          setIsChange={setIsChange}
          setIsConfirmationModal={setIsConfirmationModal}
        />
      </div>
    </>
  );
};

export default InclusionExclusion;
