import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const getCurrentHost =
  process.env.REACT_APP_MODE === "development"
    ? process.env.REACT_APP_DEVELOPMENT_LINK
    : process.env.REACT_APP_PRODUCTION_LINK;

i18n
  .use(Backend)
  // .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: JSON.parse(localStorage.getItem("language")) != null ?  JSON.parse(localStorage.getItem("language")).short_code : 'en',
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: `${getCurrentHost}/i18n/{{lng}}.json`,
    },
    cache: {
      enabled: false,
    },
  });

export default i18n;
