import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ProductBuilderContext } from "../../context";
import { data } from "jquery";
import { uplodProductNew } from "../../actions/productBuilderAction";
import { startSpinLoader } from "../../actions/customFn";

const BookingProcess = (props) => {
  const { t } = useTranslation();
  const { tour_id, option_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const languageReducer = useSelector((state) => state.languageReducer);
  const {
    setTourId,
    getData,
    setDataLoad,
    dataLoad,
    runningDtep,
    productType,
    setSpinLoader,
    saveAndExit,
    exitRoute,
    productInfo,
    languageIdSelected
  } = useContext(ProductBuilderContext);
  const [bookingProcessData, setData] = useState("");
  // const [formData, setFormData] = useState("")

  const [formData, setFormData] = useState({
    tourId: tour_id,
    product_type: productType,
    step: "thirteen",
    cut_off_way: "ONE_SET_TIME",
    booking_cut_off_time: 0,
    // cut_off_time: "01:00 AM",
    cut_off_time: "",
    cut_off_type: "DAY_OF_TRAVEL",
    confirmation_type: "INSTANT",
    notification_check: true,
    switching_time: "",
    switching_type: "hour",
    language: productInfo.productLanguageId,
    isChange:0
  });

  useEffect(() => {
    if (languageReducer != "") {
      getData("thirteen", setData);
    }
  }, [languageReducer,languageIdSelected]);

  useEffect(() => {
    if (languageReducer && bookingProcessData) {
      setFormData(() => ({
        ...bookingProcessData.product_data,
        tourId: tour_id,
        step: "thirteen",
        product_type: bookingProcessData.ProductType,
        language: productInfo.productLanguageId,
      
      }));
    }
  }, [bookingProcessData]);

  const handleChange = (e, key = "") => {
    const { name, value, checked } = e.target;
    if (name == "booking_cut_off_time" || name == "switching_time") {
      const onlyNumbers = /^[0-9]*$/;
      if (onlyNumbers.test(value) === true) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else if (name == "notification_check") {
      setFormData((formData) => ({
        ...formData,
        [name]: checked ? "true" : "false",
      }));
    } else if (name == "cut_off_way") {
      if (value == "ONE_SET_TIME") {
        setFormData((formData) => ({
          ...formData,
          [name]: value,
          booking_cut_off_time: 0,
        }));
      } else {
        setFormData((formData) => ({
          ...formData,
          [name]: value,
          cut_off_time: "",
        }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    setFormData((prev) => ({ ...prev, 'isChange': 1 }));
  };

  const handleCutOffTime = (e, data) => {
    setFormData((prev) => ({ ...prev, cut_off_time: data,'isChange':1 }));
  };

  const submit = (exit) => {
    // validateFormData();
    // if (Object.keys(errors).length === 0) {
    let nextRoute = exit || "cancelling-policy";
    if (languageReducer !== "") {
      startSpinLoader("cover-spin");
      dispatch(
        uplodProductNew(
          languageReducer,
          setTourId,
          navigate,
          formData,
          nextRoute,
          "",
          "",
          "",
          "",
          "",
          "",
          setSpinLoader
        )
      );
    }
    // }
  };

  useEffect(() => {
    if (isFirstRender == false) {
      submit(exitRoute);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  

  return (
    <>
      {bookingProcessData && (
        <div className="meet-pickup-main">
          <div className="booking_process activity_productdetail">
            <h1>{t("_Now_let's_customize_your_booking_process")}</h1>
            <h6>{t("_How_would_you_like_to_set_your_cut-off_time?")}</h6>
            <p>
              {t(
                "_This_is_the_time_when_you_stop_accepting_new_bookings_for_this_activity."
              )}
              <Link> {t("_Learn_more")}</Link>
            </p>

            <div className="box-nt-ul-selcet">
              <select
                className="form-select"
                aria-label="Default select example"
                // onChange={(e) => setSelectOnevalue(e.target.value)}
                name="cut_off_way"
                onChange={(e) => handleChange(e, "")}
                value={formData.cut_off_way}
              >
                <option value="ONE_SET_TIME">{t("_One_set_time")}</option>
                <option value="RELATIVE_TO_START_TIME">
                  {t("_Relative_to_start_time")}
                </option>
              </select>
            </div>

            {formData.cut_off_way == "RELATIVE_TO_START_TIME" ? (
              <>
                <h6>{t("_What_is_your_booking_cut-off_time_in_hours?")}</h6>
                <input
                  className="prod_numberinput"
                  type="text"
                  name="booking_cut_off_time"
                  value={formData.booking_cut_off_time}
                  onChange={handleChange}
                />
              </>
            ) : (
              <>
                <h6>{t("_Set_your_cut-off_time")}</h6>
                <div className="set_cutofftime">
                  <Autocomplete
                    className="cut-off-time"
                    options={timeSlot}
                    renderInput={(params) => <TextField {...params} />}
                    value={formData.cut_off_time}
                    onChange={handleCutOffTime}
                  />

                  <div className="box-nt-ul-selcet">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="cut_off_type"
                      onChange={(e) => handleChange(e, "")}
                      value={formData.cut_off_type}
                    >
                      <option value="DAY_OF_TRAVEL">
                        {t("_On_day_of_travel")}
                      </option>
                      <option value="ONE_DAY_BEFORE_TRAVEL">
                        {t("_1_day_before_travel")}
                      </option>
                      <option value="TWO_DAY_BEFORE_TRAVEL">
                        {t("_2_day_before_travel")}
                      </option>
                    </select>
                  </div>
                </div>
              </>
            )}

            {/* -- how long booking section -- */}

            <div className="howlong_booking">
              <h6>{t("_How_will_you_confirm_your_booking?")}</h6>
              <p>
                {/* This lets us know how and when you will process bookings for
                this product. */}
                {t(
                  "_This_lets_us_know_how_and_when_you_will_process_bookings_for_this_product"
                )}{" "}
                <Link>{t("_Learn_more")}</Link>
              </p>

              <div className="box-nt-ul-selcet">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="confirmation_type"
                  onChange={handleChange}
                  value={formData.confirmation_type}
                >
                  <option value="INSTANT">
                    {t("_Instant_confirmation_(Recommended)")}
                  </option>
                  <option value="INSTABT_THEN_MANUAL">
                    {t("_Instant_confirmation_then_becomes_manual")}
                  </option>
                  <option value="MANUAL"> {t("_Manual_confirmation")}</option>
                </select>
              </div>

              {formData.confirmation_type == "INSTANT" && (
                <label className="metting_checkboxes mt-2">
                  <input
                    type="checkbox"
                    name="notification_check"
                    onChange={handleChange}
                    checked={formData?.notification_check == "false" ? false : true}
                  />
                  <span>
                    {t("_Send_us_a_notification_email_for_each_booking")}
                  </span>
                </label>
              )}

              {formData.confirmation_type == "INSTABT_THEN_MANUAL" && (
                <>
                  <h6>
                    {t(
                      "_How_long_before_the_cut-off_time_do_you_switch_to_manual_confirmation?"
                    )}
                  </h6>
                  <div className="hourinputdiv">
                    <input
                      type="text"
                      name="switching_time"
                      value={formData.switching_time}
                      onChange={handleChange}
                    />

                    <select
                      name="switching_type"
                      onChange={handleChange}
                      value={formData.switching_type}
                    >
                      <option value="hour"> {t("_hour")}</option>
                      <option value="days">{t("_days")}</option>
                    </select>
                  </div>

                  <div className="orange_alertdiv">
                    <div className="icondiv">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 48 48"
                        fill="none"
                      >
                        <path d="M22.5 16.5H25.5V33H22.5V16.5Z" fill="white" />
                        <path d="M22.5 36H25.5V39H22.5V36Z" fill="white" />
                        <path
                          d="M25.6888 1.5H22.3112L1.5 41.7351V46.5H46.5V41.7351L25.6888 1.5ZM43.5 43.5H4.5V42.4649L24 4.76494L43.5 42.4649V43.5Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <p>
                      {t(
                        "_are_you_sure?_With_this_method,_your_product_will_switch_to_manual _confirmation_at_the_time_of_your_choice,_andthen_you'll_need_to_manually_accept _or_reject_every_booking_request_within_48_hours._This_requires_more_work_for _you,_and_can_be_a_frustrating_experience_for_travelers."
                      )}
                    </p>

                    <div className="orange_alerthide">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z"
                          fill="#737373"
                        />
                      </svg>
                    </div>
                  </div>
                </>
              )}

              {formData.confirmation_type == "MANUAL" && (
                <div className="manual_confirm">
                  <div className="orange_alertdiv">
                    <div className="icondiv">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 48 48"
                        fill="none"
                      >
                        <path d="M22.5 16.5H25.5V33H22.5V16.5Z" fill="white" />
                        <path d="M22.5 36H25.5V39H22.5V36Z" fill="white" />
                        <path
                          d="M25.6888 1.5H22.3112L1.5 41.7351V46.5H46.5V41.7351L25.6888 1.5ZM43.5 43.5H4.5V42.4649L24 4.76494L43.5 42.4649V43.5Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <p>
                      {t(
                        "_are_you_sure?_you'll_need_to_manually_accept _or_reject_each_booking_request_within_48_hours._This_could_means_more_work_for _you,_and_can_frustrating_for_travelers."
                      )}
                    </p>

                    <div className="orange_alerthide">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z"
                          fill="#737373"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              )}
              {/* 
            {selectsecondvalue == 2 ?
              <>
                <h6>How long before the cut-off time do you switch to manual confirmation?</h6>
                <div className="hourinputdiv">
                  <input type="text" />

                  <select name="cars" id="cars">
                    <option value="volvo">hour</option>
                    <option value="saab">days</option>
                  </select>
                </div>

                <div className='orange_alertdiv'>
                  <div className='icondiv'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 48 48" fill="none">
                      <path d="M22.5 16.5H25.5V33H22.5V16.5Z" fill="white" />
                      <path d="M22.5 36H25.5V39H22.5V36Z" fill="white" />
                      <path d="M25.6888 1.5H22.3112L1.5 41.7351V46.5H46.5V41.7351L25.6888 1.5ZM43.5 43.5H4.5V42.4649L24 4.76494L43.5 42.4649V43.5Z" fill="white" />
                    </svg>
                  </div>
                  <p>Are you sure? With this method, your product will switch to manual confirmation at the time of your choice, and then you'll need to manually accept or reject every booking request within 48 hours. This requires more work for you, and can be a frustrating experience for travelers.</p>

                  <div className='orange_alerthide'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z" fill="#737373" />
                  </svg></div>
                </div>

              </> : selectsecondvalue == 3 ? <div className='manual_confirm'>
                <div className='orange_alertdiv'>
                  <div className='icondiv'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 48 48" fill="none">
                      <path d="M22.5 16.5H25.5V33H22.5V16.5Z" fill="white" />
                      <path d="M22.5 36H25.5V39H22.5V36Z" fill="white" />
                      <path d="M25.6888 1.5H22.3112L1.5 41.7351V46.5H46.5V41.7351L25.6888 1.5ZM43.5 43.5H4.5V42.4649L24 4.76494L43.5 42.4649V43.5Z" fill="white" />
                    </svg>
                  </div>
                  <p>Are you sure? You’ll need to manually accept or reject each booking request within 48 hours. This could mean more work for you, and frustration for travelers.</p>


                  <div className='orange_alerthide'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z" fill="#737373" />
                  </svg></div>
                </div>
              </div> :
                <label className="metting_checkboxes mt-2">
                  <input type="checkbox" />
                  <span>Send us a notification email for each booking </span>
                </label>
            } */}
            </div>

            <div className="btn-last-save">
              <button
                className="btn-all-save"
                // onClick={() => navigate(`/product-bulider/cancelling-policy/${tour_id}`)}
                onClick={() => submit()}
              >
                {/* Save & continue */}
                {t("_Save_&_continue")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookingProcess;

const timeSlot = [
  "12:00 AM",
  "12:30 AM",
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
];

// const timeSlot = [
//   { title: '12:00 AM' },
//   { title: '12:30 AM' },
//   { title: '01:00 AM' },
//   { title: '01:30 AM' },
//   { title: '02:00 AM' },
//   { title: '02:30 AM' },
//   { title: '03:00 AM' },
//   { title: '03:30 AM' },
//   { title: '04:00 AM' },
//   { title: '04:30 AM' },
//   { title: '05:00 AM' },
//   { title: '05:30 AM' },
//   { title: '06:00 AM' },
//   { title: '06:30 AM' },
//   { title: '07:00 AM' },
//   { title: '07:30 AM' },
//   { title: '08:00 AM' },
//   { title: '08:30 AM' },
//   { title: '09:00 AM' },
//   { title: '09:30 AM' },
//   { title: '10:00 AM' },
//   { title: '10:30 AM' },
//   { title: '11:00 AM' },
//   { title: '11:30 AM' },
//   { title: '12:00 PM' },
//   { title: '12:30 PM' },
//   { title: '01:00 PM' },
//   { title: '01:30 PM' },
//   { title: '02:00 PM' },
//   { title: '02:30 PM' },
//   { title: '03:00 PM' },
//   { title: '03:30 PM' },
//   { title: '04:00 PM' },
//   { title: '04:30 PM' },
//   { title: '05:00 PM' },
//   { title: '05:30 PM' },
//   { title: '06:00 PM' },
//   { title: '06:30 PM' },
//   { title: '07:00 PM' },
//   { title: '07:30 PM' },
//   { title: '08:00 PM' },
//   { title: '08:30 AM' },
//   { title: '09:00 AM' },
//   { title: '09:30 AM' },
//   { title: '10:00 AM' },
//   { title: '10:30 AM' },
//   { title: '11:00 AM' },
//   { title: '11:30 AM' },
// ];
