import React, { useEffect, useState } from "react";

import LanguageTitle from "../components/productbuilderComponets/LanguageTitle";
import ProductCategories from "../components/productbuilderComponets/ProductCategories";
import ProductUploadPhoto from "../components/productbuilderComponets/ProductUploadPhoto";
import Meetingpickup from "../components/productbuilderComponets/Meetingpickup";
import ActivityProdDetails from "../components/productbuilderComponets/ActivityProdDetails";
import GuideActivityInfo from "../components/productbuilderComponets/GuideActivityInfo";
import InclusionExclusion from "../components/productbuilderComponets/InclusionExclusion";
import MakeProductUnique from "../components/productbuilderComponets/MakeProductUnique";
import PhysicalRestrictions from "../components/productbuilderComponets/PhysicalRestrictions";
import TravellerDetails from "../components/productbuilderComponets/TravellerDetails";
import BookingProcess from "../components/productbuilderComponets/BookingProcess";
import CancellationPolicy from "../components/productbuilderComponets/CancellationPolicy";
import TravellerRequired from "../components/productbuilderComponets/TravellerRequired";
import TicketBuilder from "../components/productbuilderComponets/TicketBuilder";
import TicketRedemption from "../components/productbuilderComponets/TicketRedemption";
import ProductMoreContent from "../components/productbuilderComponets/ProductMoreContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProductBuilderContext } from "../context";
import { getProductDataNew } from "../actions/productBuilderAction";
import PricingSchedule from "../components/productbuilderComponets/PricingSchedule";
import { startSpinLoader, stopSpinLoader } from "../actions/customFn";
import { useTranslation } from "react-i18next";
import ExtraInfromation from "../components/productbuilderComponets/ExtraInfromation";

const ProductBuilder = () => {
  const { t } = useTranslation();

  console.log("t",t("_Preview"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tour_id } = useParams();
  const { option_id } = useParams();
  const languageReducer = useSelector((state) => state.languageReducer);
  const homeData = useSelector((state) => state.home);
  const [headerLogo, setHeaderLogo] = useState("");
  // const [buttondisable, setButnDisable] = useState(2);
  // const handleChange = (event, newValue) => {
  // setValue(newValue);
  // };
  const [clickmytkettabtopic, setClickTabtopic] = useState(1);
  // ==========================================
  let location = useLocation();
  const [tourId, setTourId] = useState("");
  const [optionId, setOptionId] = useState("");
  const [dataLoad, setDataLoad] = useState(false);
  const [productType, setProductType] = useState("");
  const [runningStep, setRunningStep] = useState(0);
  const [spinLoder, setSpinLoader] = useState(false);
  const [saveAndExit, setSaveAndExit] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);

  let exitRoute = "my-tours";
  const languageData = JSON.parse(localStorage.getItem("language"));

  const [productInfo, setProductInfo] = useState({
    productTitle: "",
    slug: "",
    productLanguage: languageData.title,
    productLanguageId: languageData.id,
    productLanguageFlag: languageData.flag,
  });
  // const [productLanguageId, setProductLanguageId] = useState("");
  const [activeStep, setActiveStep] = useState(1);
  const languageList = useSelector((state) => state.languageList);

  const [languageIdSelected, setLanguageIdSelected] = useState("");
  const [googleKey, setGoogleKey] = useState("");

  

  useEffect(() => {
    if (homeData && homeData.settings) {
      setHeaderLogo(homeData.settings.header_logo);
      setGoogleKey(homeData?.settings?.google_key);
      // setGoogleKey("AIzaSyAfBpNqHgVLTp9kzzYQn92_4oOV4yM8MtM");
    }
  }, [homeData]);

  const getData = (step, setData, sendData = "") => {
    if (languageReducer !== "") {
      // coverSpinLoader("cover-spin");
      startSpinLoader("cover-spin");
      // setDataLoad(true);
      dispatch(
        getProductDataNew(
          languageReducer,
          step,
          tour_id,
          setData,
          navigate,
          option_id,
          sendData,
          setRunningStep,
          setProductType,
          setDataLoad,
          setSpinLoader,
          setProductInfo,
          setActiveStep,
          languageIdSelected,
          setIsConfirmationModal
        )
      );
    }
  };

  useEffect(() => {
    if (languageReducer != "" && tour_id != undefined) {

      if (languageIdSelected != "") {
        let result = languageList.find((obj) => obj.id == languageIdSelected);
        setProductInfo((prev) => ({
          ...prev,
          productLanguage: result.title,
          productLanguageId: result.id,
          productLanguageFlag: result.flag,
        }));
  
 
      }
      
    }
  }, [languageReducer, languageIdSelected]);

  

  useEffect(() => {
    if (dataLoad == true) {
      stopSpinLoader("cover-spin");
    }
  }, [spinLoder]);

  localStorage.setItem("mytketrunningStep", JSON.stringify(runningStep));
  localStorage.setItem(
    "clickmytkettabtopic",
    JSON.stringify(clickmytkettabtopic)
  );

  const mytketrunningStep = parseInt(
    JSON.parse(localStorage.getItem("mytketrunningStep"))
  );

  const currentTab = parseInt(
    JSON.parse(localStorage.getItem("clickmytkettabtopic"))
  );

  useEffect(() => {
    if (activeStep >= 4 && activeStep <= 10) {
      setClickTabtopic(2);
    }
    if (activeStep >= 12 && activeStep <= 12) {
      setClickTabtopic(3);
    }
    if (activeStep >= 13) {
      setClickTabtopic(4);
    }
  }, [activeStep]);


  return (
    <ProductBuilderContext.Provider
      value={{
        getData,
        setTourId,
        productType,
        setProductType,
        setOptionId,
        setDataLoad,
        dataLoad,
        runningStep,
        setRunningStep,
        googleKey, 
        spinLoder,
        setSpinLoader,
        saveAndExit,
        exitRoute,
        productInfo,
        setProductInfo,
        languageIdSelected,
        setLanguageIdSelected,
        isConfirmationModal,
        setIsConfirmationModal
        // setProductLanguageId,
        // saveAndExitRef
      }}
    >
      <div className="product_buliderpage container-fluid">
        {/* -- ashu -- */}
        <div className="productbulid_header">
          <div className="head_lefticon">
            <NavLink to="/">
              {" "}
              <img src={headerLogo} alt="" />
            </NavLink>
          </div>

          <div className="head_rightname">
            <h4>
              {/* {

              } */}
              {productInfo.productTitle
                ? productInfo.productTitle
                : "New Project"}
              {/* New Project */}
              {productInfo.slug && (
                <NavLink
                  className={"preview_header_link"}
                  to={`/product-preview/${productInfo.slug}`}
                  target="_blank"
                >
                  {t("_Preview")}{" "}
                </NavLink>
              )}
              <button
                // onClick={() => navigate("/my-tours")}
                onClick={() => setSaveAndExit((status) => !status)}
                // onClick={() => Exit()}
              >
                {" "}
                {t("_Save_&_exit")}{" "}
              </button>{" "}
              <button onClick={() => navigate("/my-tours")}>
                {" "}
                {t("_exit")}{" "}
              </button>{" "}
            </h4>
          </div>
          {/* <div >
            <h4>
              Produt data is saving in {productInfo.productLanguage}
            </h4>
          </div> */}
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className=" col-xl-2 col-lg-2 col-md-12 col-sm-12 column_nopadding">
              {/* <div className="left_progressbar">
              <h4>20% Complete</h4>
              <Box sx={{ width: "100%" }}>
                <LinearProgress variant="determinate" value={20} />
              </Box>
            </div> */}

              <div className="tabsection_leftbulidprod">
                <h6
                  className=" cursor-pointer"
                  onClick={() => setClickTabtopic(1)}
                >
                  {t("_BASICS")}
                </h6>

                <NavLink
                  to={`/product-bulider/product-language-title/${
                    tour_id ? tour_id : ""
                  }`}
                  className={currentTab == 1 ? "" : "tab_producthide"}
                >
                  <span>{t("_Language_and_title")} </span>{" "}
                  {runningStep > 1 && ( 
                    <FontAwesomeIcon icon="fa-solid fa-check" />
                  )}
                </NavLink>

                {runningStep > 1 ? (
                  <NavLink
                    to={`/product-bulider/categorization/${tour_id}`}
                    className={currentTab == 1 ? "" : "tab_producthide"}
                  >
                    <span>{t("_Categorization")}</span>{" "}
                    {runningStep > 2 && (
                      <FontAwesomeIcon icon="fa-solid fa-check" />
                    )}
                  </NavLink>
                ) : (
                  <div
                    className={
                      currentTab == 1
                        ? "tabsnavlargehide_overgray"
                        : "tab_producthide "
                    }
                  >
                    <p>{t("_Categorization")}</p>
                  </div>
                )}

                {runningStep > 2 ? (
                  <NavLink
                    to={`/product-bulider/photos/${tour_id}`}
                    className={currentTab == 1 ? "" : "tab_producthide"}
                  >
                    {t("_Photos")}
                    {runningStep > 3 && (
                      <FontAwesomeIcon icon="fa-solid fa-check" />
                    )}
                  </NavLink>
                ) : (
                  <div
                    className={
                      currentTab == 1
                        ? "tabsnavlargehide_overgray"
                        : "tab_producthide "
                    }
                  >
                    <p>{t("_Photos")} </p>
                  </div>
                )}

                {mytketrunningStep > 3 ? (
                  <h6
                    className="producttab_maintopics"
                    onClick={() => setClickTabtopic(2)}
                  >
                    {t("_PRODUCT_CONTENT")}
                  </h6>
                ) : (
                  <div className="tabshide_overgray">
                    {" "}
                    <p>{t("_PRODUCT_CONTENT")}</p>{" "}
                  </div>
                )}

                {runningStep > 3 ? (
                  <NavLink
                    to={`/product-bulider/more-content/${tour_id}`}
                    className={currentTab == 2 ? "" : "tab_producthide"}
                  >
                    {t("_More_content")}{" "}
                    {runningStep > 4 && (
                      <FontAwesomeIcon icon="fa-solid fa-check" />
                    )}
                  </NavLink>
                ) : (
                  <div
                    className={
                      currentTab == 2
                        ? "tabsnavlargehide_overgray"
                        : "tab_producthide "
                    }
                  >
                    <p> {t("_More_content")} </p>{" "}
                  </div>
                )}

                {runningStep > 4 ? (
                  <NavLink
                    to={`/product-bulider/meeting-pickup/${tour_id}`}
                    className={currentTab == 2 ? "" : "tab_producthide"}
                  >
                    {t("_Meeting_&_pickup")}{" "}
                    {runningStep > 5 && (
                      <FontAwesomeIcon icon="fa-solid fa-check" />
                    )}
                  </NavLink>
                ) : (
                  <div
                    className={
                      currentTab == 2
                        ? "tabsnavlargehide_overgray"
                        : "tab_producthide "
                    }
                  >
                    <p> {t("_Meeting_&_pickup")} </p>{" "}
                  </div>
                )}
                {runningStep > 5 ? (
                  <NavLink
                    to={`/product-bulider/activity-detail/${tour_id}`}
                    className={currentTab == 2 ? "" : "tab_producthide"}
                  >
                    {productType === "tour" && t("_tour_details")}{" "}
                    {productType === "activity" && t("_Activity_details")}{" "}
                    {productType === "ticket_and_pass" && t("_ticket_details")}{" "}
                    {runningStep > 6 && (
                      <FontAwesomeIcon icon="fa-solid fa-check" />
                    )}
                  </NavLink>
                ) : (
                  <div
                    className={
                      currentTab == 2
                        ? "tabsnavlargehide_overgray"
                        : "tab_producthide "
                    }
                  >
                    <p> {t("_Activity_details")} </p>{" "}
                  </div>
                )}

                {runningStep > 6 ? (
                  <NavLink
                    to={`/product-bulider/guide-activity-info/${tour_id}`}
                    className={currentTab == 2 ? "" : "tab_producthide"}
                  >
                    {t("_Guide_&_activity_info")}{" "}
                    {runningStep > 7 && (
                      <FontAwesomeIcon icon="fa-solid fa-check" />
                    )}
                  </NavLink>
                ) : (
                  <div
                    className={
                      currentTab == 2
                        ? "tabsnavlargehide_overgray"
                        : "tab_producthide "
                    }
                  >
                    {" "}
                    <p> {t("_Guide_&_activity_info")} </p>{" "}
                  </div>
                )}

                {runningStep > 7 ? (
                  <NavLink
                    to={`/product-bulider/inclusion-exclusion/${tour_id}`}
                    className={currentTab == 2 ? "" : "tab_producthide"}
                  >
                    {t("_Inclusions_&_exclusions")}{" "}
                    {runningStep > 8 && (
                      <FontAwesomeIcon icon="fa-solid fa-check" />
                    )}
                  </NavLink>
                ) : (
                  <div
                    className={
                      currentTab == 2
                        ? "tabsnavlargehide_overgray"
                        : "tab_producthide "
                    }
                  >
                    {" "}
                    <p> {t("_Inclusions_&_exclusions")} </p>{" "}
                  </div>
                )}

                {runningStep > 8 ? (
                  <NavLink
                    to={`/product-bulider/unique-product/${tour_id}`}
                    className={currentTab == 2 ? "" : "tab_producthide"}
                  >
                    {t("_What_makes_your_product_unique")}{" "}
                    {runningStep > 9 && (
                      <FontAwesomeIcon icon="fa-solid fa-check" />
                    )}
                  </NavLink>
                ) : (
                  <div
                    className={
                      currentTab == 2
                        ? "tabsnavlargehide_overgray"
                        : "tab_producthide "
                    }
                  >
                    {" "}
                    <p>{t("_What_makes_your_product_unique")} </p>{" "}
                  </div>
                )}

                {runningStep > 9 ? (
                  <NavLink
                    to={`/product-bulider/physical-restriction/${tour_id}`}
                    className={currentTab == 2 ? "" : "tab_producthide"}
                  >
                    {t("_Information_travelers_need_from_you")}{" "}
                    {runningStep > 10 && (
                      <FontAwesomeIcon icon="fa-solid fa-check" />
                    )}
                  </NavLink>
                ) : (
                  <div
                    className={
                      currentTab == 2
                        ? "tabsnavlargehide_overgray"
                        : "tab_producthide "
                    }
                  >
                    {" "}
                    <p>{t("_Information_travelers_need_from_you")}</p>{" "}
                  </div>
                )}


                {runningStep > 10 ? (
                  <NavLink
                    to={`/product-bulider/extra-information/${tour_id}`}
                    className={currentTab == 2 ? "" : "tab_producthide"}
                  >
                    {"Extra Information"}
                    {runningStep > 11 && (
                      <FontAwesomeIcon icon="fa-solid fa-check" />
                    )}
                  </NavLink>
                ) : (
                  <div
                    className={
                      currentTab == 2
                        ? "tabsnavlargehide_overgray"
                        : "tab_producthide "
                    }
                  >
                    {" "}
                    <p>{"Extra Information"}</p>{" "}
                  </div>
                )}

                {mytketrunningStep > 11 ? (
                  <h6
                    className="producttab_maintopics"
                    onClick={() => setClickTabtopic(3)}
                  >
                    {t("_SCHEDULES_&_PRICING")}
                  </h6>
                ) : (
                  <div className="tabshide_overgray">
                    <p>{t("_SCHEDULES_&_PRICING")}</p>
                  </div>
                )}

               

                {runningStep > 10 ? (
                  <>
                    {/* <NavLink
                    to={`/product-bulider/traveller-details/${tour_id}`}
                    className={currentTab == 3 ? "" : "tab_producthide"}
                  >
                    Traveler details{" "}
                    {runningStep > 11 && (
                      <FontAwesomeIcon icon="fa-solid fa-check" />
                    )}
                  </NavLink> */}

                    {runningStep > 11 ? (
                      <NavLink
                        to={`/product-bulider/price-schedule/${tour_id}`}
                        className={currentTab == 3 ? "" : "tab_producthide"}
                      >
                        {t("_Pricing_schedules")}{" "}
                        {runningStep > 13 && (
                          <FontAwesomeIcon icon="fa-solid fa-check" />
                        )}
                      </NavLink>
                    ) : (
                      <div
                        className={
                          currentTab == 3
                            ? "tabsnavlargehide_overgray"
                            : "tab_producthide "
                        }
                      >
                        <p>{t("_Pricing_schedules")} </p>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}

                {runningStep > 11 ? (
                  <h6
                    className="producttab_maintopics"
                    onClick={() => setClickTabtopic(4)}
                  >
                    {t("_BOOKING_&_TICKETS")}
                  </h6>
                ) : (
                  <div className="tabshide_overgray">
                    <p> {t("_BOOKING_&_TICKETS")}</p>
                  </div>
                )}
                {runningStep > 11 ? (
                  <>
                    <NavLink
                      to={`/product-bulider/booking-process/${tour_id}`}
                      className={currentTab == 4 ? "" : "tab_producthide"}
                    >
                      {t("_Booking_process")}{" "}
                      {runningStep > 12 && (
                        <FontAwesomeIcon icon="fa-solid fa-check" />
                      )}
                    </NavLink>

                    {runningStep > 12 ? (
                      <NavLink
                        to={`/product-bulider/cancelling-policy/${tour_id}`}
                        className={currentTab == 4 ? "" : "tab_producthide"}
                      >
                        {t("_Cancellation_policy")}{" "}
                        {runningStep > 13 && (
                          <FontAwesomeIcon icon="fa-solid fa-check" />
                        )}
                      </NavLink>
                    ) : (
                      <div
                        className={
                          currentTab == 4
                            ? "tabsnavlargehide_overgray"
                            : "tab_producthide "
                        }
                      >
                        <p> {t("_Cancellation_policy")} </p>
                      </div>
                    )}

                    {/* {runningStep > 14 ? (
                    <NavLink
                      to={`/product-bulider/traveller-required/${tour_id}`}
                      className={currentTab == 4 ? "" : "tab_producthide"}
                    >
                      Traveler required information{" "}
                      {runningStep > 15 && (
                        <FontAwesomeIcon icon="fa-solid fa-check" />
                      )}
                    </NavLink>
                  ) : (
                    <div
                      className={
                        currentTab == 2
                          ? "tabsnavlargehide_overgray"
                          : "tab_producthide "
                      }
                    >
                      <p> Traveler required information </p>
                    </div>
                  )} */}

                    {runningStep > 13 ? (
                      <NavLink
                        to={`/product-bulider/ticket-builder/${tour_id}`}
                        className={currentTab == 4 ? "" : "tab_producthide"}
                      >
                        {t("_Ticket_builder")}
                        {runningStep > 14 && (
                          <FontAwesomeIcon icon="fa-solid fa-check" />
                        )}
                      </NavLink>
                    ) : (
                      <div
                        className={
                          currentTab == 4
                            ? "tabsnavlargehide_overgray"
                            : "tab_producthide "
                        }
                      >
                        <p> {t("_Ticket_builder")} </p>
                      </div>
                    )}

                    {runningStep > 15 ? (
                      <NavLink
                        to={`/product-bulider/ticket-redemption/${tour_id}`}
                        className={currentTab == 4 ? "" : "tab_producthide"}
                      >
                        {t("_Ticket_redemption")}{" "}
                        {runningStep >= 17 && (
                          <FontAwesomeIcon icon="fa-solid fa-check" />
                        )}
                      </NavLink>
                    ) : (
                      <div
                        className={
                          currentTab == 4
                            ? "tabsnavlargehide_overgray"
                            : "tab_producthide "
                        }
                      >
                        <p> {t("_Ticket_redemption")} </p>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className=" col-xl-10 col-lg-10 col-md-12 col-sm-12 column_nopadding tabs_contbgcolor">
              <div id="cover-spin"></div>
              <div className="product_builderbody new-builder-language">
                <div className="row new__vpagebuilder">
                  <div className=" col-xl-8 col-lg-8 col-md-12 col-sm-12">
                    <div>
                      {location.pathname.split("/")[2] ==
                      "product-language-title" ? (
                        <LanguageTitle /> 
                      ) : location.pathname ===
                        `/product-bulider/categorization/${tour_id}` ? (
                        <ProductCategories />
                      ) : location.pathname ===
                        `/product-bulider/photos/${tour_id}` ? (
                        <ProductUploadPhoto />
                      ) : location.pathname ===
                        `/product-bulider/meeting-pickup/${tour_id}` ? (
                        <Meetingpickup />
                      ) : location.pathname ===
                        `/product-bulider/more-content/${tour_id}` ? (
                        <ProductMoreContent />
                      ) : location.pathname ===
                        `/product-bulider/activity-detail/${tour_id}` ? (
                        <ActivityProdDetails />
                      ) : location.pathname ===
                        `/product-bulider/guide-activity-info/${tour_id}` ? (
                        <GuideActivityInfo />
                      ) : location.pathname ===
                        `/product-bulider/inclusion-exclusion/${tour_id}` ? (
                        <InclusionExclusion />
                      ) : location.pathname ===
                        `/product-bulider/unique-product/${tour_id}` ? (
                        <MakeProductUnique />
                      ) : location.pathname ===
                        `/product-bulider/physical-restriction/${tour_id}` ? (
                        <PhysicalRestrictions />
                      ) : location.pathname ===
                      `/product-bulider/extra-information/${tour_id}` ? (
                         <ExtraInfromation />
                      ) : location.pathname ===
                        `/product-bulider/traveller-details/${tour_id}` ? (
                        <TravellerDetails />
                      ) : location.pathname ===
                        `/product-bulider/price-schedule/${tour_id}` ? (
                        <PricingSchedule />
                      ) : location.pathname ===
                        `/product-bulider/booking-process/${tour_id}` ? (
                        <BookingProcess />
                      ) : // navigate("/my-tours")
                      location.pathname ===
                        `/product-bulider/cancelling-policy/${tour_id}` ? (
                        <CancellationPolicy />
                      ) : location.pathname ===
                        `/product-bulider/traveller-required/${tour_id}` ? (
                        <TravellerRequired />
                      ) : location.pathname ===
                        `/product-bulider/ticket-builder/${tour_id}` ? (
                        <TicketBuilder />
                      ) : location.pathname ===
                        `/product-bulider/ticket-redemption/${tour_id}` ? (
                        <TicketRedemption />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className=" col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <div className="product-language-note">
                      <div className="top-header-meetp">
                        {/* <h2>Tell us how and where you meet your travelers</h2> */}
                        <div className="top-header-info-box">
                          <div className="icon-svg-info">
                            <svg
                              viewBox="0 0 27 36"
                              xmlns="http://www.w3.org/2000/svg"
                              className="Alert__alertIcon___S7wRU Icon__icon___2sbfU"
                            >
                              <g fillRule="nonzero">
                                <path d="M17.97 34.81v.25h-9a.7.7 0 01-.7-.7V28.5h1.4v5.16h7.6V28.5h1.4v5.86a.7.7 0 01-.7.7v-.25z"></path>
                                <path d="M20.22 23.45l.24-.23c.77-.76 1.11-1.12 1.51-1.65a11.5 11.5 0 003.05-7.82A11.6 11.6 0 0013.43 2.2 11.57 11.57 0 005 21.63c.35.47.69.83 1.46 1.59l.24.23c1.77 1.74 2.52 2.98 2.56 5h8.42c.03-2.02.78-3.26 2.55-5zm-2.25 5.74v.75h-9a1.23 1.23 0 01-1.22-1.21c.03-1.7-.52-2.64-2.12-4.22l-.23-.22c-.83-.81-1.2-1.2-1.56-1.7A13.03 13.03 0 01.4 13.76C.4 6.56 6.24.69 13.45.69a13.1 13.1 0 0113.06 13.07 13 13 0 01-3.4 8.77c-.41.55-.78.95-1.61 1.76l-.23.23c-1.6 1.57-2.14 2.51-2.11 4.2a1.2 1.2 0 01-1.2 1.22v-.75z"></path>
                                <path d="M4.44 14H3.29v-.24a10.2 10.2 0 014.2-8.23l.2-.15.82 1.13-.2.15a8.8 8.8 0 00-3.62 7.1h-.25V14zM9.22 5.79l-.5-1.03.22-.11c.36-.18.69-.32 1.01-.44l.24-.08.48 1.31-.24.09c-.28.1-.56.22-.87.37l-.11-.22-.23.1zM7.67 32.25v-1.4h11.6v1.4z"></path>
                              </g>
                            </svg>
                          </div>
                          <div className="meet-box-content">
                            <h2>{t("_NOTE:")}</h2>
                            <p>
                              {t("_All_your_product_data_will_be_saved_in")}
                              <span className="language-name">
                                {productInfo.productLanguage}
                                <img
                                  src={productInfo.productLanguageFlag}
                                  alt=""
                                />
                              </span>
                              {t("_language.")}
                            </p>
                           
                            <div className="box-nt-ul-selcet">
                              <select
                                className="form-select cursor-pointer"
                                aria-label="Default select example"
                                name="language"
                                onChange={(e) =>
                                  setLanguageIdSelected(e.target.value)
                                }
                                value={languageIdSelected == "" ? languageReducer?.id : languageIdSelected}
                              >
                                {languageList.length > 0 &&
                                  languageList.map((value, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={value.id}
                                        className="cursor-pointer"
                                      >
                                        {value.title}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Note: Product data will be n in {productInfo.productLanguage} language */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProductBuilderContext.Provider>
  );
};

export default ProductBuilder;
