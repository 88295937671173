import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ProductBuilderContext } from "../../context";
import { uplodProductNew } from "../../actions/productBuilderAction";
import { startSpinLoader, validateField } from "../../actions/customFn";
import { Select, MenuItem } from "@mui/material";

const CancellationPolicy = (props) => {
  const { t } = useTranslation();
  const { tour_id, option_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const {
    setTourId,
    getData,
    productType,
    setSpinLoader,
    saveAndExit,
    productInfo,
    exitRoute,
    languageIdSelected,
  } = useContext(ProductBuilderContext);

  const [cancellationData, setData] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [formData, setFormData] = useState({
    tourId: tour_id,
    product_type: productType,
    step: "fifteen",
    language: productInfo.productLanguageId,
    policy_type: "STANDARD",
    bad_weather: false,
    not_enough_travellers: false,
    cancelled_time: "1",
    cancelled_type: "day",
    isChange:0
  });

  const handleChange = (e, key = "") => {
    const { name, value, checked } = e.target;
    if (name == "policy_type" || name == "cancelled_type") {
      setFormData((formData) => ({ ...formData, [name]: value }));
    } else if (name == "cancelled_time") {
      const onlyNumbers = /^[0-9]*$/;
      if (onlyNumbers.test(value) === true) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setFormData((formData) => ({ ...formData, [name]: checked.toString() })); //conversion in string
    }
    setFormData((formData) => ({ ...formData, 'isChange': 1 }));
  };

  useEffect(() => {
    if (languageReducer != "") {
      getData("fifteen", setData);
    }
  }, [languageReducer, languageIdSelected]);

  useEffect(() => {
    if (languageReducer && cancellationData) {
      setFormData(() => ({
        ...cancellationData.product_data,
        tourId: tour_id,
        step: "fifteen",
        product_type: cancellationData.ProductType,
        language: productInfo.productLanguageId,
      }));
    }
  }, [cancellationData]);

  const submit = (exit) => {
    // validateFormData();


    
    let validate = {
      cancelled_time: "Required|",
    };
    validateField(validate, { ...formData }).then((res) => {
      console.log("formData:", formData , res);
      if (res == true) {
        let nextRoute = exit || "ticket-builder";
        if (languageReducer !== "") {
          startSpinLoader("cover-spin");
          dispatch(
            uplodProductNew(
              languageReducer,
              setTourId,
              navigate,
              formData,
              nextRoute,
              "",
              "",
              "",
              "",
              "",
              "",
              setSpinLoader
            )
          );
        }
      } else {
        setSpinLoader((status) => !status);
      }
    });
  };

  useEffect(() => {
    if (isFirstRender == false) {
      submit(exitRoute);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  const cancelledTypeOption = {
    day: t("_Days"),
    hour: t("_Hours"),
    minute: t("_Minutes"),
  };


  return (
    <>
      {cancellationData && (
        <div className="meet-pickup-main">
          <div className="cancellationpolicy activity_productdetail">
            <h1>{t("_Your_cancellation_policy")}</h1>

            <h6>
              {t("_Select_your_cancellation_policy")}
              <Link>{t("_Learn_more")}</Link>
            </h6>

            <div className="top-header-info-box">
              <div className="icon-svg-info">
                <svg
                  viewBox="0 0 27 36"
                  xmlns="http://www.w3.org/2000/svg"
                  className="Alert__alertIcon___S7wRU Icon__icon___2sbfU"
                >
                  <g fillRule="nonzero">
                    <path d="M17.97 34.81v.25h-9a.7.7 0 01-.7-.7V28.5h1.4v5.16h7.6V28.5h1.4v5.86a.7.7 0 01-.7.7v-.25z"></path>
                    <path d="M20.22 23.45l.24-.23c.77-.76 1.11-1.12 1.51-1.65a11.5 11.5 0 003.05-7.82A11.6 11.6 0 0013.43 2.2 11.57 11.57 0 005 21.63c.35.47.69.83 1.46 1.59l.24.23c1.77 1.74 2.52 2.98 2.56 5h8.42c.03-2.02.78-3.26 2.55-5zm-2.25 5.74v.75h-9a1.23 1.23 0 01-1.22-1.21c.03-1.7-.52-2.64-2.12-4.22l-.23-.22c-.83-.81-1.2-1.2-1.56-1.7A13.03 13.03 0 01.4 13.76C.4 6.56 6.24.69 13.45.69a13.1 13.1 0 0113.06 13.07 13 13 0 01-3.4 8.77c-.41.55-.78.95-1.61 1.76l-.23.23c-1.6 1.57-2.14 2.51-2.11 4.2a1.2 1.2 0 01-1.2 1.22v-.75z"></path>
                    <path d="M4.44 14H3.29v-.24a10.2 10.2 0 014.2-8.23l.2-.15.82 1.13-.2.15a8.8 8.8 0 00-3.62 7.1h-.25V14zM9.22 5.79l-.5-1.03.22-.11c.36-.18.69-.32 1.01-.44l.24-.08.48 1.31-.24.09c-.28.1-.56.22-.87.37l-.11-.22-.23.1zM7.67 32.25v-1.4h11.6v1.4z"></path>
                  </g>
                </svg>
              </div>
              <div className="meet-box-content">
                {/* <h2>Did you know?</h2> */}
                <p>
                  {/* Most travelers prefer the flexibility of a standard
                  cancellation policy. Your product is also more likely to
                  obtain an Excellent quality status. */}
                  {t(
                    "_most_travelers_prefer_the_flexibility_of_a_standard_cancellation_policy_Your_product_is_also_more_likely_to_obtain_an_excellent_quality_status."
                  )}
                </p>
              </div>
            </div>

            <div className="cancel_radiobtns">
              <div className="product_selectorradio mt-5">
                <input
                  type="radio"
                  className="productradio cancellation-policy-radio-btn"
                  name="policy_type"
                  value="STANDARD"
                  checked={formData.policy_type == "STANDARD" ? true : false}
                  onChange={handleChange}
                />
                <span>
                  {t("_Standard")}
                  <div className="recommended"> {t("_RECOMMENDED")}</div>
                </span>
              </div>
              <p>
                {t(
                  "To_receive_a_full_refund_travelers_may_cancel_up_to_24_hours_before_the_experience_start_time_in_the_local_timezone._No_refunds_will_be_given_after_that_time_period."
                )}
              </p>

              <div className="product_selectorradio">
                <input
                  type="radio"
                  className="productradio cancellation-policy-radio-btn"
                  name="policy_type"
                  value="All_SALES_FINAL"
                  checked={
                    formData.policy_type == "All_SALES_FINAL" ? true : false
                  }
                  onChange={handleChange}
                />
                <span>{t("_All_sales_final")}</span>
              </div>
              <p>
                {t(
                  "_travelers_will_not_receive_any_refund_regardless_of_cancellation_status."
                )}
              </p>

              <div className="product_selectorradio">
                <input
                  type="radio"
                  className="productradio cancellation-policy-radio-btn"
                  name="policy_type"
                  value="CUSTOM_POLICY"
                  checked={
                    formData.policy_type == "CUSTOM_POLICY" ? true : false
                  }
                  onChange={handleChange}
                />
                <span>{t("_Custom_Policy_Time")}</span>
              </div>
              <p>
                {t(
                  "_How_long_before_the_activity_can_the_customer_cancel_or_change_a_reservation_without_losing_money"
                )}
              </p>
            </div>
            <div className="time_data_main">
              <div className="radio_btn_transpoetantion">
                {((formData.policy_type == "CUSTOM_POLICY") == true ||
                  (formData.policy_type == "CUSTOM_POLICY") == "true") && (
                  <div className="pricing-schedule-cancel-time">
                    <div>
                      <input
                        type="text"
                        name="cancelled_time"
                        value={formData.cancelled_time}
                        onChange={(e) => handleChange(e)}
                      />
                      <Select
                        sx={{
                          width: "100%",
                        }}
                        className="input_username new-edit-main-all select-field"
                        id="cancelled_type"
                        name="cancelled_type"
                        displayEmpty
                        value={formData.cancelled_type}
                        onChange={(e) => handleChange(e)}
                      >
                        {Object.entries(cancelledTypeOption).map(
                          (item, index) => {
                            return (
                              <MenuItem key={index} value={item[0]}>
                                {item[1]}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <h6>
              {t("_Add_to_your_policy")}
              <span>({t("_optional")})</span>{" "}
            </h6>
            <p>
              {/* You may reserve the right to cancel a customer's booking for a
              full refund in case of: */}
              {t(
                "_You_may_reserve_the_right_to_cancel_a_customer's_booking_for_a_full_refund_in_case_of:"
              )}
            </p>

            <label className="metting_checkboxes mt-3">
              <input
                type="checkbox"
                name="bad_weather"
                onChange={handleChange}
                checked={formData.bad_weather == "true" ? true : false}
              />
              <span>{t("_Bad_weather")}</span>
            </label>

            <label className="metting_checkboxes mt-2">
              <input
                type="checkbox"
                name="not_enough_travellers"
                onChange={handleChange}
                checked={
                  formData.not_enough_travellers == "true" ? true : false
                }
              />
              <span> {t("_Not_enough_travelers")}</span>
            </label>

            <div className="btn-last-save">
              <button className="btn-all-save" onClick={() => submit()}>
                {t("_Save_&_continue")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CancellationPolicy;
