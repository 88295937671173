import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ProductBuilderContext } from "../../context";
import { getArray, startSpinLoader } from "../../actions/customFn";
import { uplodProductNew } from "../../actions/productBuilderAction";
import { useTranslation } from "react-i18next";

const PhysicalRestrictions = (props) => {
  const { t } = useTranslation();
  const [accessiblilitydata, setData] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tour_id } = useParams();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const languageReducer = useSelector((state) => state.languageReducer);
  const {
    setTourId,
    getData,
    productType,
    setSpinLoader,
    saveAndExit,
    exitRoute,
    productInfo,
    languageIdSelected,
  } = useContext(ProductBuilderContext);
  const errors = {};

  // ----------Get Unique Product Details------------
  useEffect(() => {
    if (languageReducer != "") {
      getData("ten", setData);
    }
  }, [languageReducer, languageIdSelected]);

  const [getAccessibilty, setAccessibilty] = useState([]);
  const [getRestriction, setRestriction] = useState([]);

  const [difficultylevel, setDifficultylevel] = useState("");
  const [isChange, setIsChange] = useState(0);

  useEffect(() => {
    if (accessiblilitydata) {
      setAccessibilty(
        accessiblilitydata.product_data?.accessibility != undefined
          ? accessiblilitydata.product_data?.accessibility
          : []
      );
      setRestriction(
        accessiblilitydata.product_data?.restriction != undefined
          ? accessiblilitydata.product_data?.restriction
          : []
      );
      setDifficultylevel(
        accessiblilitydata.product_data?.difficulty_level != undefined
          ? accessiblilitydata.product_data?.difficulty_level
          : ""
      );
      setIsChange(accessiblilitydata.product_data?.isChange);
    }
  }, [accessiblilitydata]);

  const addAcessInput = () => {
    const DuplicateData = {
      accessibility_id: "",
      accessibility_title: "",
      accessibility_value: "",
    };

    setAccessibilty([...getAccessibilty, DuplicateData]);
  };
  const removeAccessInpt = (key) => {
    const newData = [...getAccessibilty];
    newData.splice(key, 1);
    setAccessibilty(newData);
    setIsChange(1);
  };

  const addRestrictInput = () => {
    const DuplicateDatas = {
      restriction_id: "",
      restriction_title: "",
      restriction_value: "",
    };

    setRestriction([...getRestriction, DuplicateDatas]);
  };

  const removeRestrictInput = (key) => {
    const newData = [...getRestriction];
    newData.splice(key, 1);
    // delete newData[2];
    setRestriction(newData);
    setIsChange(1);
  };

  const handleChangess = (e, key) => {
    let data = [...getAccessibilty];
    data[key].accessibility_title = e.target.value;
    setAccessibilty(data);
    setIsChange(1);
  };

  const handleRestrictChange = (e, key) => {
    let data = [...getRestriction];
    data[key].restriction_title = e.target.value;
    setRestriction(data);
    setIsChange(1);
  };

  const changeRcheck = (e, key) => {
    const val = e.target.checked;
    if (val == true) {
      getRestriction[key].restriction_value = "true";
    } else if (val == false) {
      getRestriction[key].restriction_value = "false";
    }
    setIsChange(1);
  };

  const changeAcheck = (key, value) => {
    if (value == "no") {
      getAccessibilty[key].accessibility_value = "false";
    } else {
      getAccessibilty[key].accessibility_value = "true";
    }
    setIsChange(1);
  };

  const handleSubmit = (e, exit) => {
    if (e) {
      e.preventDefault();
    }
    // validateFormData();

    if (Object.keys(errors).length === 0) {
      const sendData = {
        id: getArray(getAccessibilty, "id"),
        accessibility_id: getArray(getAccessibilty, "accessibility_id"),
        accessibility_title: getArray(getAccessibilty, "accessibility_title"),
        accessibility_value: getArray(getAccessibilty, "accessibility_value"),
        restriction_id: getArray(getRestriction, "restriction_id"),

        restriction_title: getArray(getRestriction, "restriction_title"),
        restriction_value: getArray(getRestriction, "restriction_value"),
        difficulty_level: difficultylevel,
        step: "ten",
        tourId: tour_id,
        isChange: isChange,
        product_type: productType,
        language: productInfo.productLanguageId,
      };
      // let nextRoute = "traveller-details";
      let nextRoute = exit || "extra-information";
      if (languageReducer !== "") {
        startSpinLoader("cover-spin");
        dispatch(
          uplodProductNew(
            languageReducer,
            setTourId,
            navigate,
            sendData,
            nextRoute,
            "",
            "",
            "",
            "",
            "",
            "",
            setSpinLoader
          )
        );
      }
    }
  };

  useEffect(() => {
    if (isFirstRender == false) {
      handleSubmit("", exitRoute);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  const difficultyLevelFn = (level) => {
    setDifficultylevel(level);
    setIsChange(1);
  };


  return (
    <>
      <div className="meet-pickup-main">
        <div id="cover-spin"></div>
        <div className="prodphysical_restrictions activity_productdetail">
          <h1>
            {/* Add any physical restrictions */}
            {t("_Add_any_physical_restrictions")}
          </h1>
          <p>
            {/* This information will help travelers know if this is a good activity
            for them. */}
            {t(
              "_This_information_will_help_travelers_know_if_this_is_a_good_activity_for_them."
            )}
          </p>

          <div className="restric_tableone">
            <div className="row">
              <div className="col-8">
                <h6>{t("_Accessibility")}</h6>
              </div>
              <div className="col-2 text-center">
                <h6>{t("_Yes")}</h6>
              </div>
              <div className="col-2 text-center">
                <h6>{t("_No")}</h6>
              </div>
            </div>
          </div>

          {getAccessibilty != undefined &&
            getAccessibilty?.map((value, index) => {
              return (
                <>
                  {value.accessibility_id != "" && (
                    <div className="restric_tableone">
                      <div className="row">
                        <div className="col-8">
                          <p>{value.accessibility_title}</p>
                        </div>
                        <div className="col-2 text-center">
                          <div className="product_selectorradio_accessibility">
                            <input
                              type="radio"
                              className="productradio"
                              onChange={(e) => changeAcheck(index, "yes")}
                              defaultChecked={
                                value.accessibility_value == "true"
                                  ? true
                                  : false
                              }
                              name={"radio" + index}
                            />
                          </div>
                        </div>
                        <div className="col-2 text-center">
                          <div className="product_selectorradio_accessibility">
                            <input
                              type="radio"
                              className="productradio"
                              onChange={(e) => changeAcheck(index, "no")}
                              defaultChecked={
                                value.accessibility_value == "false"
                                  ? true
                                  : false
                              }
                              name={"radio" + index}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}

          <button className="trasnparent_buton mt-2" onClick={addAcessInput}>
            + {t("_Add_another")}
          </button>
          {getAccessibilty != undefined &&
            getAccessibilty?.map((value, index) => {
              return (
                <div key={index}>
                  {value.accessibility_id == "" && (
                    <div className="inputwith_dltbtn mb-2">
                      <input
                        type="text"
                        name="accessibility_title"
                        value={value.accessibility_title}
                        onChange={(e) => handleChangess(e, index)}
                      />
                      <button
                        type="button"
                        onClick={(e) => removeAccessInpt(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z"
                            fill="#d0d0d0"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              );
            })}

          <h6>
            {/* Health restrictions */}
            {t("_Health_restrictions")}
          </h6>
          <p>{t("_Check_all_that_apply")}</p>

          {getRestriction != undefined &&
            getRestriction.map((val, index) => {
              return (
                <>
                  {val.restriction_id != "" && (
                    <label className="metting_checkboxes mt-3">
                      <input
                        type="checkbox"
                        defaultChecked={
                          val.restriction_value == "true" ? true : false
                        }
                        onChange={(e) => changeRcheck(e, index)}
                      />
                      <p>{val.restriction_title}</p>
                    </label>
                  )}
                </>
              );
            })}

          <div className=" mt-2">
            <button
              className="trasnparent_buton mt-2"
              onClick={addRestrictInput}
            >
              + {t("_Add_another")}
            </button>

            {getRestriction != undefined &&
              getRestriction.map((val, index) => {
                return (
                  <>
                    {val.restriction_id == "" && (
                      <div className="inputwith_dltbtn mb-2">
                        <input
                          type="text"
                          onChange={(e) => handleRestrictChange(e, index)}
                          value={val.restriction_title}
                          id={index}
                        />
                        <button onClick={(e) => removeRestrictInput(index)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z"
                              fill="#d0d0d0"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </>
                );
              })}
          </div>

          <h6>
            {/* Select the physical difficulty level */}
            {t("_Select_the_physical_difficulty_level")}
          </h6>
          <div className="restrict_radio3box">
            <div className="product_selectorradio">
              <input
                type="radio"
                className="productradio"
                name="easy"
                onChange={() => difficultyLevelFn("easy")}
                checked={difficultylevel == "easy" ? true : false}
              />
              <span>{t("_Easy")}</span>
            </div>
            <p>{t("_Most_travelers_can_participate")}</p>

            <div className="product_selectorradio">
              <input
                type="radio"
                className="productradio"
                onChange={() => difficultyLevelFn("moderate")}
                name="easy"
                checked={difficultylevel == "moderate" ? true : false}
              />
              <span>{t("_Moderate")}</span>
            </div>
            <p>
              {t("_Travelers_should_have_a_moderate_physical_fitness_level")}
            </p>

            <div className="product_selectorradio">
              <input
                type="radio"
                className="productradio"
                onChange={() => difficultyLevelFn("challenege")}
                name="easy"
                checked={difficultylevel == "challenege" ? true : false}
              />
              <span>{t("_Challenging")}</span>
            </div>
            <p>{t("_Travelers_should_have_a_strong_physical_fitness_level")}</p>
          </div>

          <div className="btn-last-save">
            <button onClick={handleSubmit} className="btn-all-save">
              {" "}
              {t("_Save_&_continue")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhysicalRestrictions;
